import { Box, Grid } from '@mui/material'
import BillingDetails from './BillingDetails'
import OrderSummary from './OrderSummary'
import { useTranslation } from 'react-i18next'
import { Button, Container } from '@mui/joy'
import { useGetAllCartsQuery } from '../../../src/APIs/cartApi'
import { button } from './checkTypes'
export type CheckOutJaririProps = {
  orderSummary?: boolean
  color?: {
    title?: string
    label?: string
  }
  button?: button
}
const CheckOutJariri = (props: CheckOutJaririProps) => {
  const [, { language, changeLanguage }] = useTranslation()
  const { data, isSuccess } = useGetAllCartsQuery(undefined)

  return (
    <Box
      sx={{
        direction: language === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '90%' },
          mx: 'auto',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row-reverse' },
          }}
        >
          <Grid item xs={12} md={8}>
            <BillingDetails color={props.color} button={props.button} />
          </Grid>

          <Grid item xs={12} md={3.5}>
            <OrderSummary />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default CheckOutJariri
