import * as React from 'react'
import { Button, Menu, Stack, Typography } from '@mui/material'
import { ProfileMenuData } from '../navdata'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { store } from './../../src/store/Store'
import { savedProductsApi } from '../../src/APIs/SavedProductApi'
import cartApi from '../../src/APIs/cartApi'
import { setSaved } from '../../src/APIs/savedSlice'
import { setCart } from '../../src/APIs/cartSlice'
import CloseIcon from '@mui/icons-material/Close'
import Person2Icon from '@mui/icons-material/Person2'

import { removeCurrentUser } from '../../src/APIs/userSlice'
import { useLogoutMutation } from '../../src/APIs/UserApis'

interface ProfileButtonProps {
  iconColor: string
  bgColor: string
  textColor: string
  activeColor: string
  menuBgColor: string
  menuItemBgColor: string
}
interface currentUserType {
  _id: string
  registrationType: string
  passwordResetVerified: boolean
  name: string
  email: string
  password: string
  image: string
  revinue: number
  role: string
  orders: any[] // or provide a more specific type for the "orders" array
  favourite: any[] // or provide a more specific type for the "favourite" array
  changePasswordAt: string
  createdAt: string
  updatedAt: string
  __v: number
  imageUrl: string
  id: string
  phone: string
}
export default function ProfileButton(colors: ProfileButtonProps) {
  const { currentUser } = useSelector(
    (state: { currentUser: currentUserType }) => state
  )
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [logout] = useLogoutMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const open = Boolean(anchorEl)
  const handleLogout = () => {
    handleClose()
    logout(undefined).then(() => {
      localStorage.clear()
      store.dispatch(savedProductsApi.util.resetApiState())
      store.dispatch(cartApi.util.resetApiState())
      dispatch(setSaved(0))
      dispatch(setCart(0))
      navigate('/')
      dispatch(removeCurrentUser())
    })
  }
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [, { language }] = useTranslation()
  const handleClickMenuItem = (item: any) => {
    item.name_en === 'Logout' ? handleLogout() : navigate(item.path)
    handleClose()
  }
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        sx={{
          width: {
            md: 50,
            xs: 40,
          },
          height: {
            md: 40,
            xs: 30,
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 0,
          borderRadius: 1,
          bgcolor: `${colors.bgColor} !important`,
        }}
      >
        {open ? (
          <CloseIcon
            sx={{
              fontSize: {
                lg: '30px',
                xs: '25',
              },
              color: colors.iconColor,
            }}
          />
        ) : (
          <Person2Icon
            sx={{
              fontSize: {
                lg: '30px',
                xs: '25px',
              },
              color: colors.iconColor,
            }}
          />
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          width: '100%',
          '.MuiPaper-root': {
            bgcolor: colors.menuBgColor,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {ProfileMenuData.filter(({ name_en }) =>
          (currentUser && currentUser?.email) ||
          (currentUser?.phone && currentUser?.role !== 'guest')
            ? name_en !== 'Login' && name_en !== 'Register'
            : name_en !== 'Profile' && name_en !== 'Logout'
        ).map((item, idx ) => (
          <Stack
            key={item.name_en}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => handleClickMenuItem(item)}
            sx={{
              cursor: 'pointer',
              gap: '10px',
              p: '10px',
              mb: idx === 0 ? '5px' : 0,

              bgcolor: `${colors.menuItemBgColor} !important`,

              svg: {
                color: `${colors.textColor} !important`,
              },
            }}
          >
            {item.icon}
            <Typography
              sx={{
                fontWeight: 'bold',
                color: `${colors.textColor} !important`,
              }}
            >
              {language === 'en' ? item.name_en : item.name_ar}
            </Typography>
          </Stack>
        ))}
      </Menu>
    </div>
  )
}
