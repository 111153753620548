import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LoginIcon from '@mui/icons-material/Login'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import LogoutIcon from '@mui/icons-material/Logout'
import CategoryIcon from '@mui/icons-material/Category'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import MapsUgcIcon from '@mui/icons-material/MapsUgc'
import { useFetchCategoriesWithSubs } from './category/useFetchCategories'
export const NavData = () => {
  const { categoriesWithSubs } = useFetchCategoriesWithSubs()
  const nestedLinks =
    categoriesWithSubs.data.length > 0
      ? categoriesWithSubs.data.map((item: any) => {
          return {
            id: item.category['id'],
            title_en: item.category.name_en,
            title_ar: item.category.name_ar,
            subs: item.subCategories.map((sub: any) => ({
              id: sub.id,
              title_en: sub.name_en,
              title_ar: sub.name_ar,
            })),
          }
        })
      : []
  return [
    {
      link_en: 'Home',
      link_ar: 'الرئيسية',
      icon: <HomeIcon />,
      path: '/',
    },
    {
      link_en: 'Categories',
      link_ar: 'الأقسام',
      path: '/departments',
      icon: <CategoryIcon />,
      nestedLinks: nestedLinks,
    },
    {
      link_en: 'About Us',
      link_ar: 'حولنا',
      path: '/aboutUs',
      icon: <InfoIcon />,
    },
    {
      link_en: 'Contact Us',
      link_ar: 'تواصل معنا',
      path: '/contactUs',
      icon: <MapsUgcIcon />,
    },
  ]
}

const iconStyle = {
  color: '#363636 !important',
  fontSize: '20px',
}
export const ProfileMenuData = [
  {
    name_en: 'Login',
    name_ar: 'تسجيل الدخول',
    path: 'sign-in',
    icon: <LoginIcon sx={iconStyle} />,
  },
  {
    name_en: 'Register',
    name_ar: 'تسجيل حساب',
    path: 'register',
    icon: <AppRegistrationIcon sx={iconStyle} />,
  },
  {
    name_en: 'Profile',
    name_ar: 'الملف الشخصي',
    path: 'profile',
    icon: <AccountCircleIcon sx={iconStyle} />,
  },
  {
    name_en: 'Logout',
    name_ar: 'تسجيل خروج',
    path: '',
    icon: <LogoutIcon sx={iconStyle} />,
  },
]
