import { useEffect, useState } from 'react'
import {
  useGetAllCategoriesQuery,
  useGetAllCategoriesWithSubsQuery,
} from '../../src/APIs/categoriesApi'
import { useTranslation } from 'react-i18next'
const useFetchCategories = () => {
  const { data, isSuccess, isError } = useGetAllCategoriesQuery(
    'limit=100',
    undefined
  )

  const [categories, setCategories] = useState([])
  const [, setError] = useState('')
  useEffect(() => {
    if (isSuccess && !isError) {
      setCategories(data?.data)
      setError('')
    } else {
      setError('Error While Fetching Products')
    }
  }, [isSuccess])
  return categories
}
export const useFetchCategoriesWithSubs = () => {
  const { data, error } = useGetAllCategoriesWithSubsQuery('limit=1000')
  const [_, { language }] = useTranslation()
  const [categoriesWithSubs, setCategories] = useState({
    data: [],
    error: '',
  })
  useEffect(() => {
    if (data && !error) {
      console.log('data of categories', data)
      setCategories({
        data: data.data,
        error: `${
          language === 'en'
            ? 'Error while fetching categories'
            : 'حدث خطأ أثناء جلب الأقسام'
        }`,
      })
    } else {
      console.log('error of categories', error)
      setCategories({
        data: [],
        error: `${
          language === 'en'
            ? 'Error while fetching categories'
            : 'حدث خطأ أثناء جلب الأقسام'
        }`,
      })
    }
  }, [data, error])
  return { categoriesWithSubs }
}
export default useFetchCategories
