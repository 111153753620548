import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { ReactElement } from 'react'
import { NavTypes } from './NavTypes'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTranslation } from 'react-i18next'
import Logo from './Logo'
import { NavData } from './navdata'
// import { LogInButton } from './publicComponents/LogInButton'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import NavMenu from './NavMenu'
import NavCategoryMenu from './NavCategoryMenu'

interface NavDrawerProp extends NavTypes {
  mobileOpen: boolean
  handleDrawerToggle: () => void
  muiIcons: ReactElement[]
}
export const NavDrawer = (props: NavDrawerProp) => {
  const [, { language: lng }] = useTranslation()
  const navLinks = NavData()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  return (
    <nav>
      <Drawer
        variant="temporary"
        anchor={lng === 'en' ? 'left' : 'right'}
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
            pt: '60px',
            pb: '10px',
            bgcolor: props.drawerBg ? props.colors.backgroundColor : 'white',
          },
        }}
      >
        <Stack
          sx={{
            textAlign: 'center',
            height: '100%',
            direction: lng === 'en' ? 'ltr' : 'rtl',
          }}
          direction={'column'}
          alignItems={'center'}
        >
          <Box
            sx={{
              borderBottom: `1px solid ${props.colors.borderColor}`,
              flex: 1,
              mb: 2,
              width: '100%',
            }}
          >
            <ArrowBackIcon
              sx={{
                display: 'block',
                cursor: 'pointer',
                textAlign: 'left',
                ml: lng === 'en' ? 2 : 0,
                mr: lng === 'ar' ? 2 : 0,
                color: props.colors.drawerColor,
                transform: `rotate(${lng === 'ar' ? '180deg' : '0deg'})`,
              }}
              onClick={props.handleDrawerToggle}
            />
            <Box
              sx={{ width: '30%', height: '90px', mx: 'auto' }}
              onClick={props.handleDrawerToggle}
            >
              <Logo imagePath={props.logoPath} extraObjectFit={'contain'} />
            </Box>

            <List>
              {navLinks?.map((item, index) =>
                !item.nestedLinks ? (
                  <ListItem
                    component={NavLink}
                    key={index}
                    to={item?.path}
                    onClick={() => {
                      props.handleDrawerToggle()
                    }}
                    disablePadding
                    sx={{
                      width: props.buttonStyle ? '90%' : '100%',
                      px: '2px',
                      span: {
                        color: `${props.colors.drawerColor} !important`,
                      },
                      svg: {
                        color: `${props.colors.drawerColor} !important`,
                      },
                      mx: 'auto',
                      mb: props.buttonStyle ? '10px' : '0px',
                      '&.active': {
                        borderLeft:
                          lng === 'en'
                            ? props.buttonStyle
                              ? `4px solid ${props.colors.activeDrawerColor}`
                              : `1px solid ${props.colors.borderColor}`
                            : `1px solid transparent`,
                        borderRight:
                          lng === 'ar'
                            ? props.buttonStyle
                              ? `4px solid ${props.colors.activeDrawerColor}`
                              : `1px solid ${props.colors.borderColor}`
                            : `1px solid transparent`,
                        span: {
                          color: `${props.colors.activeDrawerColor} !important`,
                        },
                        svg: {
                          color: `${props.colors.activeDrawerColor} !important`,
                        },

                        borderRadius: '3px',
                      },
                    }}
                  >
                    <ListItemButton
                      sx={{
                        textAlign:
                          !props.blockStyle && !props.buttonStyle
                            ? 'initial'
                            : 'center',
                        color: props.colors.linkTextColor,
                        borderRadius: props.buttonStyle
                          ? props.buttonStyle === 'radius'
                            ? 3
                            : 6
                          : 0,
                        py: 0,
                        border:
                          props.blockStyle || props.buttonStyle
                            ? `.2px solid ${props.colors.borderColor}`
                            : 'none',
                        borderTop: {
                          lg:
                            props.blockStyle || props.buttonStyle
                              ? `.2px solid ${props.colors.borderColor}`
                              : 'none',
                          xs:
                            index !== 0
                              ? `1px solid ${
                                  props.buttonStyle
                                    ? props.colors.borderColor
                                    : 'transparent'
                                }`
                              : `.2px solid transparent`,
                        },

                        bgcolor: `${props.colors.linkTextBgColor} !important`,
                        ...(props.sideMenuWithHover && {
                          '&:hover': {
                            color: props.colors.linkTextHoverColor,
                            bgcolor: props.colors.linkTextHoverBgColor,
                            '& .MuiListItemIcon-root, & .MuiListItemText-primary':
                              {
                                color: props.colors.linkTextHoverColor,
                              },
                          },
                        }),
                      }}
                    >
                      {props.muiIcons !== undefined && props.icons && (
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            color:
                              pathname === item.path
                                ? props.colors.activeLinkBgColor
                                : props.colors.linkTextColor,
                          }}
                        >
                          {props.muiIcons[index]}
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={lng === 'en' ? item.link_en : item.link_ar}
                        sx={{
                          color:
                            pathname === item.path
                              ? props.colors.activeDrawerColor
                              : props.colors.linkTextColor,
                          '.MuiListItemText-primary': {
                            fontSize: '1.1rem',
                            py: 1,
                          },
                          span: {
                            textAlign:
                              !props.buttonStyle && !props.blockStyle
                                ? 'initial'
                                : 'center',
                          },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : !props.menu ? (
                  <>
                    {item.nestedLinks.length > 1 && (
                      <Box
                        width={props.blockStyle ? 1 : 0.9}
                        mx={'auto'}
                        sx={{
                          mb: props.blockStyle ? 0 : '10px',
                          pr: {
                            lg: 0,
                            xs: lng === 'ar' ? '2px' : undefined,
                          },
                          pl: {
                            lg: 0,
                            xs: lng === 'en' ? '2px' : undefined,
                          },
                          borderLeft: {
                            lg: 0,
                            xs:
                              lng === 'en'
                                ? props.buttonStyle &&
                                  pathname === `/departments`
                                  ? `4px solid ${props.colors.activeDrawerColor}`
                                  : `1px solid transparent`
                                : `1px solid transparent`,
                          },
                          borderRight: {
                            lg: 0,
                            xs:
                              lng === 'ar'
                                ? props.buttonStyle &&
                                  pathname === `/departments`
                                  ? `4px solid ${props.colors.activeDrawerColor}`
                                  : `1px solid transparent`
                                : `1px solid transparent`,
                          },
                        }}
                      >
                        <Button
                          sx={{
                            width: 1,
                            border:
                              props.blockStyle || props.buttonStyle
                                ? `1px solid ${props.colors.borderColor}`
                                : 'none',
                            borderTop: {
                              lg:
                                props.blockStyle || props.buttonStyle
                                  ? `1px solid ${props.colors.borderColor}`
                                  : 'none',
                              xs: `1px solid ${
                                props.buttonStyle
                                  ? props.colors.borderColor
                                  : `transparent`
                              }`,
                            },
                            borderBottom: {
                              lg: !props.buttonStyle
                                ? `2px solid ${
                                    pathname === `/departments`
                                      ? props.colors.activeBorderColor
                                      : null
                                  }`
                                : `1px solid ${props.colors.borderColor}`,
                              xs: `1px solid ${
                                props.buttonStyle
                                  ? props.colors.borderColor
                                  : `transparent`
                              }`,
                            },
                            color: {
                              lg:
                                pathname === `/departments`
                                  ? props.colors.activeLinkColor
                                  : props.colors.linkTextColor,
                              xs:
                                pathname === `/departments`
                                  ? props.colors.activeDrawerColor
                                  : props.colors.drawerColor,
                            },
                            bgcolor: `${props.colors.linkTextBgColor} !important`,
                            cursor: 'pointer',
                            fontSize: {
                              xs: '1rem',
                              md: '1rem',
                              lg: '1.2rem',
                            },
                            textTransform: 'capitalize',
                            borderRadius: props?.buttonStyle
                              ? props.buttonStyle === 'radius'
                                ? 3
                                : 6
                              : 0,
                            p: props.blockStyle
                              ? '.7rem 1rem'
                              : props.buttonStyle
                              ? '10px'
                              : 0,
                            justifyContent:
                              !props.blockStyle && !props.buttonStyle
                                ? 'flex-start'
                                : 'center',
                          }}
                          onClick={() => {
                            navigate(`/departments`)
                            props.handleDrawerToggle()
                          }}
                        >
                          {lng === 'en' ? 'All Categories' : 'جميع الأقسام'}
                        </Button>
                      </Box>
                    )}
                    {item.nestedLinks?.map((element: any) =>
                      !element.subs[0] ? (
                        <Box
                          sx={{
                            width: props.blockStyle ? 1 : 0.9,
                            mx: 'auto',
                            mb: props.blockStyle ? 0 : '10px',
                            pr: {
                              lg: 0,
                              xs: lng === 'ar' ? '2px' : undefined,
                            },
                            pl: {
                              lg: 0,
                              xs: lng === 'en' ? '2px' : undefined,
                            },
                            borderLeft: {
                              lg: 0,
                              xs:
                                lng === 'en'
                                  ? props.buttonStyle &&
                                    pathname.includes(
                                      `/departments/${element.id}`
                                    )
                                    ? `4px solid ${props.colors.activeDrawerColor}`
                                    : `1px solid transparent`
                                  : `1px solid transparent`,
                            },
                            borderRight: {
                              lg: 0,
                              xs:
                                lng === 'ar'
                                  ? props.buttonStyle &&
                                    pathname.includes(
                                      `/departments/${element.id}`
                                    )
                                    ? `4px solid ${props.colors.activeDrawerColor}`
                                    : `1px solid transparent`
                                  : `1px solid transparent`,
                            },
                          }}
                        >
                          <Button
                            key={element.id}
                            title={
                              element[`title_${lng === 'en' ? 'en' : 'ar'}`]
                                .length > 11
                                ? element[`title_${lng === 'en' ? 'en' : 'ar'}`]
                                : null
                            }
                            sx={{
                              width: 1,
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',

                              border:
                                props.blockStyle || props.buttonStyle
                                  ? `1px solid ${props.colors.borderColor}`
                                  : 'none',

                              color: {
                                lg:
                                  pathname === `/departments/${element.id}`
                                    ? props.colors.activeLinkColor
                                    : props.colors.linkTextColor,
                                xs:
                                  pathname === `/departments/${element.id}`
                                    ? props.colors.activeDrawerColor
                                    : props.colors.drawerColor,
                              },
                              bgcolor: `${props.colors.linkTextBgColor} !important`,
                              cursor: 'pointer',
                              fontSize: '18px',
                              textTransform: 'capitalize',
                              borderRadius: props.buttonStyle
                                ? props.buttonStyle === 'radius'
                                  ? 3
                                  : 6
                                : 0,
                              p: props.blockStyle
                                ? '.7rem 1rem'
                                : props.buttonStyle
                                ? '10px'
                                : 0,
                              justifyContent:
                                !props.blockStyle && !props.buttonStyle
                                  ? 'flex-start'
                                  : 'center',
                            }}
                            onClick={() => {
                              navigate(`/departments/${element.id}`)
                              props.handleDrawerToggle()
                            }}
                          >
                            {element[`title_${lng}`].length > 13
                              ? element[`title_${lng}`].slice(0, 13) + '...'
                              : element[`title_${lng}`]}
                          </Button>
                        </Box>
                      ) : (
                        <NavCategoryMenu item={element} {...props} />
                      )
                    )}
                  </>
                ) : (
                  <NavMenu {...props} item={item} />
                )
              )}
            </List>
          </Box>
        </Stack>
      </Drawer>
    </nav>
  )
}
