import { Box, Typography, Stack, Container } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import data from '../../data.json'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { useGetAllCartsQuery } from '../../../src/APIs/cartApi'
import { imageBaseUrl } from '../../../src/App'

const cartPrice = (total: number, quantity: number, price:number)=>{
  const pricePlusTax = total/quantity;
  const tax = pricePlusTax-price;
  return tax;
}

const OrderSummary = () => {
  const [_, { language }] = useTranslation()
  const { data, isSuccess } = useGetAllCartsQuery(undefined)
  const cashItems=data?.data.cashItems?.items||[]
  const onlineItems=data?.data.onlineItems?.items||[]
  const productInCart = [...cashItems,...onlineItems]

  return (
    <Box>
      <Container
        sx={{
          mt: 4,
          p: 2,
        }}
      >
        {/* prouduct with images */}

        <Box
          sx={{
            overflowY: 'auto', // Apply scroll only if more than 3 items
            height: { xs: '300px', md: '400px' },
          }}
        >
          {productInCart?.map((item: any) => (
            <Stack
              direction="row"
              sx={{
                mt: 2,
                mb: 2,
                p: 2,
                borderBottom: '2px solid #e0e0e0',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  width: { xs: '100px', md: '100px' },
                  height: { xs: '100px', md: '70px' },
                  overflow: 'hidden',
                  borderRadius: '10px',
                  boxShadow: '0px 0px 5px 0px #e0e0e0',
                }}
              >
                <img
                  src={`${imageBaseUrl}${item.product.images[0]}`}
                  alt={item.title}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>

              <Box>
                <Typography sx={{
                    color:"#000"
                }}>{item.product[`title_${language}`]}</Typography>
              </Box>

              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <AttachMoneyIcon sx={{
                    color:"#000"
                }} />
                <Typography sx={{
                    color:"#000"
                }}>{item.product.priceAfterDiscount?
                  (cartPrice(item.total, item.quantity, item.product.finalPrice) + item.product.priceAfterDiscount )
                :(cartPrice(item.total, item.quantity, item.product.finalPrice) + item.product.priceBeforeDiscount ) }</Typography>
              </Stack>
            </Stack>
          ))}
        </Box>

        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 7,
            mb: 2,
            pb: 2,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color:"#000"
              }}
            >
              {language === 'en' ? 'Cash on delivery:' : 'الدفع عند الاستلام'}
            </Typography>
          </Box>

          {/* get user order */}
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color:"#000"
              }}
            >
              {data?.data.cashItems.totalPrice}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 4,
            mb: 2,
            pb: 2,
            borderBottom: '2px solid #e0e0e0',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color:"#000"
              }}
            >
              {language === 'en' ? 'Online payment:' : ' الدفع الالكتروني'}
            </Typography>
          </Box>

          {/* get user order */}
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color:"#000"
              }}
            >
              {data?.data.onlineItems.totalPrice}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 4,
            mb: 2,
            pb: 2,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color:"#000"
              }}
            >
              {language === 'en' ? 'Total' : ' الاجمالي'}
            </Typography>
          </Box>

          {/* get user order */}
          <Box>
            <Typography
              sx={{
                fontSize: { xs: '20px', md: '25px' },
                color:"#000"
              }}
            >
              {data?.data.totalPrice}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default OrderSummary
