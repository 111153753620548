import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ParentDepartmentPage } from '../AllProducts/ParentDepartmentPage'
// import { Footer3 } from '../Footer/Footer3'
import { Profile } from '../Profile/Profile'
// import { useSelector } from 'react-redux'
import AuthenticationUser from '../AuthenticationUser/AuthenticationUser'
// import { Cart04 } from '../Cart/Cart04'
// import { Cart03 } from '../Cart/Cart03'
// import { Cart02 } from '../Cart/Cart02'
// import { Cart01 } from '../Cart/Cart01'

// import { Connections } from '../ContactUs/ContactUs2/ContactData/ConnectionsData'
// import ContactUs from '../ContactUs/ContactUs1/ContactUs'
import ContactUs from '../ContactUs/ContactUs03/ContactUs'

// import dataFooter from '../Footer/data.json'

import { Navbar } from '../Nav/Navbar'
// import CheckOutJariri from '../PaymentPage/tomoor1/checkOut/CheckOutTomoor1'

import { Privacy } from '../Privacy/Privacy'
import { TomoorSingleProduct } from '../SingleProduct/TomoorSingleProduct'
// import ProtectedRoutes from './ProtectedRoutes'
// import test from './test.jpg'
// import video from '../HeroSection/testVideo.mp4'
import Home from '../HomePage/Home'
import homeHeroImg from './assets/home-hero.png'
import aboutImage from './assets/about1.GIF'
import imgAbout1 from './assets/about2.png'
import imgAbout2 from './assets/about2.png'
//
// export const imageBaseUrl = `https://mofarag.me/uploads/`
// export const imageBaseUrl = `https://saritestsecond.online:8080/uploads/`
export const imageBaseUrl = `https://lifeandbite.com:8080/uploads/`
// import backSvg from './assets/Generative.png'
import img2 from './assets/contact.png'
import logo from './assets/logo1.svg'
import imgAboutPage from './assets/about.jpg'
import backgroundLogin from './assets/login.png'
// import { useGetMostSellingProductsQuery } from './APIs/ProductApis'
// import { type } from './../SingleProduct/SingleProductTypes';
// import data from '../Footer/data.json'
// import { Footer3 } from '../Footer/Footer3'
import video from './assets/video.mp4'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Footer from '../Footer/Footer'
import ForgetPass from '../Forget Password/ForgetPass'
import PaymentMoyasar from '../PaymentMoyasar/PaymentMoyasar'
import ThanksPage from '../ThanksPage/ThanksPage'
import { useLazyGetMeQuery } from './APIs/UserApis'
import { useCreateGuestUserMutation } from './APIs/gestUserApi'
import { setCurrentUser } from './APIs/userSlice'
// import CheckOutTomoor from '../PaymentPage/tomoor/checkOut/CheckOutTomoor'
// import CheckOutTomoor1 from '../PaymentPage/tommor1/checkOut/CheckOutTomoor1'
import { useTranslation } from 'react-i18next'
import CheckOutJariri from '../PaymentPage/jariri/checkOut/CheckOutJariri'
import { Cart04 } from '../Cart/Cart04'
import { AboutUs3 } from '../AboutUs/AboutUs3'
// import { NoonSingleProduct } from '../SingleProduct/NoonSingleProduct'
// import { ShishiSingleProduct } from '../SingleProduct/ShishiSingleProduct'
// import { JaririSingleProduct } from '../SingleProduct/JaririSingleProduct'
export const App = () => {
  const dispatch = useDispatch()
  // const { currentUser }: any = useSelector((state) => state)
  const [createGuestUser] = useCreateGuestUserMutation()
  // console.log(currentUser)
  const [, { language: lang, changeLanguage }] = useTranslation()
  useEffect(() => {
    const sessionState = localStorage.getItem('token')

    if (!sessionState) {
      createGuestUser(undefined).then((response) => {
        if ('data' in response) {
          const { data } = response
          console.log(data)
          if (data?.token) {
            localStorage.setItem('token', data.token)
          }
        } else {
          const { error } = response
          console.error(error)
          // Handle the error case here
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('token')])
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [getMe] = useLazyGetMeQuery()

  useEffect(() => {
    getMe(undefined).then(({ data }) => {
      if (data?.data.role !== 'guest') {
        dispatch(setCurrentUser(data?.data))
      }
    })
  }, [dispatch, getMe])
  useEffect(() => {
    if (localStorage.i18nextLng === 'en') {
      changeLanguage('ar')
    }
  }, [])
  return (
    <BrowserRouter>
      <Navbar
        direction="horizontal"
        colors={{
          borderColor: '#5B0888',
          activeBorderColor: '#6D3B2A',
          linkTextColor: '#6D3B2A',
          linkTextBgColor: 'e5e5e5',
          activeLinkColor: '#6D3B2A',
          activeLinkBgColor: '#28ACB3',
          drawerColor: '#6D3B2A',
          activeDrawerColor: '#6D3B2A',
          buttonBgColor: '#6D3B2A',
          buttonColor: '#fff',
          backgroundColor: '#bf9fea',
          iconColor: '#6D3B2A',
          activeIconColor: '#6D3B2A',
          menuBgColor: '#fff',
          menuItemColor: '#6D3B2A',
          activeMenuItemColor: '#6D3B2A',
          badgeColors: {
            text: '#fff',
            bg: '#6D3B2A',
          },
        }}
        logoPath={logo}
        // sideMenuWithBorder
        // buttonStyle=""
        // sideMenuWithHover
        // drawerBg
        // navBg
        menu
        icons
      />

      <Routes>
        <Route
          path="/"
          element={
            <Home
              // bgOfBody="#ffff" when you need to change the background color of the home page
              hero={{
                type: 'HeroBack1',
                heroBackgroundColor: '#F3F2F1',
                color: {
                  titleColor: '#6D3B2A',
                  subTitleColor: '#6D3B2A',
                },
              }}
              // heroBackgroundVideo={video}
              heroBackgroundImage={homeHeroImg}
              heroImage={img2}
              newest={{
                type: 'NewestCollections',
                typeCollections: 'Newest',
                backgroundColor: '#fff',
                color: '#6D3B2A',
                card: {
                  colors: {
                    backgroundColor: 'gray',
                    buttonTextColor: 'white',
                    titleColor: 'gray',
                    descColor: 'gray',
                    buttonBackgroundColor: 'transparent',
                    borderColor: 'white',
                    hoverColor: 'white',
                  },
                  height: '100%',
                  width: '100%',
                  favorite: true,
                  hover: true,
                },
                BtnCollections: {
                  title: lang === 'en' ? 'Shopp now' : 'تسوق الآن',
                  titleColor: '#fff',
                  backgroundColor: '#6D3B2A',
                  borderRadius: true,
                },
              }}
              about={{
                image: aboutImage,
                backgroundColor: '#F5F1F0',
                color: {
                  title: '#6D3B2A',
                  subTitle: '#6D3B2A',
                },
                button: {
                  color: 'white',
                  backgroundColor: '#6D3B2A',
                },
              }}
              // vision={{
              //   button: {
              //     color: 'white',
              //     backgroundColor: '#4e6f72',
              //     borderRadius: true,
              //   },

              //   video: video,
              //   background: '#f1f7f6',
              //   color: {
              //     title: 'red',
              //     subTitle: 'blue',
              //   },
              // }}

              fixedCardData={{
                title_en: 'Most Seller',
                title_ar: 'الأكثر مبيعاً',
                card: {
                  colors: {
                    backgroundColor: 'white',
                    buttonTextColor: 'white',
                    titleColor: '#6D3B2A',
                    descColor: '#6D3B2A',
                    buttonBackgroundColor: 'transparent',
                    borderColor: '#6D3B2A',
                  },
                  height: '100%',
                  width: '100%',
                  title: true,
                  desc: true,
                  button: false,
                  borderRadius: true,
                },
              }}
            />
          }
        />
        <Route
          path="/departments"
          element={
            <ParentDepartmentPage
              grid
              type="allProducts"
              card={{
                colors: {
                  titleColor: '#6D3B2A',
                  descColor: '#6D3B2A',
                  buttonBackgroundColor: '#5B0888',
                  buttonTextColor: '#fff',
                },
                height: 300,
                width: 300,
                borderRadius: true,
                title: true,
                desc: true,
                favorite: true,
                // button: true,
              }}
            />
          }
        />
        <Route
          path="/departments/:categoryId"
          element={
            <ParentDepartmentPage
              type="category"
              card={{
                colors: {
                  titleColor: '#6D3B2A',
                  descColor: '#6D3B2A',
                  buttonBackgroundColor: '#5B0888',
                  buttonTextColor: '#fff',
                },
                height: 300,
                width: 300,
                borderRadius: true,
                title: true,
                desc: true,
                favorite: true,
                // button: true,
              }}
            />
          }
        />
        <Route
          path="/departments/:categoryId/:subId"
          element={
            <ParentDepartmentPage
              type="subCategory"
              card={{
                colors: {
                  titleColor: '#6D3B2A',
                  descColor: '#6D3B2A',
                  buttonBackgroundColor: '#5B0888',
                  buttonTextColor: '#fff',
                },
                height: 300,
                width: 300,
                borderRadius: true,
                title: true,
                desc: true,
                favorite: true,
                // button: true,
              }}
            />
          }
        />

        <Route
          path="/products/:id"
          element={
            <TomoorSingleProduct
              colors={{
                titleColor: '#6D3B2A',
                descColor: '#6D3B2A',
                buttonColor: '#fff',
                buttonBgColor: '#6D3B2A',
                priceBeforeColor: '#6D3B2A',
                priceAfterColor: '#6D3B2A',
                attrKeyColor: '#6D3B2A',
                attrValueColor: '#6D3B2A',
                attrValueBgColor: '#6D3B2A',
                attrValueBorderColor: '#6D3B2A',
              }}
            />
          }
        />

        <Route
          path="/checkOut"
          element={
            <CheckOutJariri
              color={{
                title: '#6D3B2A',
                label: '#6D3B2A',
              }}
              button={{
                color: 'white',
                backgroundColor: '#6D3B2A',
              }}
            />
          }
        />
        <Route path="/payment-order" element={<PaymentMoyasar />} />
        <Route
          path="/aboutUs"
          element={
            <AboutUs3
              imgs={[imgAbout1, imgAbout2]}
              colors={{
                titleColor: '#6D3B2A',
                descColor: '#6D3B2A',
                bgColor1: '#F3F2F1',
                bgColor2: '#F3F2F1',
              }}
            />
          }
        />

        <Route
          path="/products"
          element={
            <ParentDepartmentPage
              grid
              type="allProducts"
              card={{
                colors: {
                  titleColor: '#5B0888',
                  descColor: '#bf9fea',
                  buttonBackgroundColor: '#5B0888',
                  buttonTextColor: '#fff',
                },
                height: { xs: 200, sm: 225, md: 250, lg: 300 },
                width: { xs: 150, sm: 175, md: 200, lg: 250 },
                title: true,
                desc: true,
                button: true,
                borderRadius: true,
              }}
            />
          }
        />

        <Route
          path="/cart"
          element={
            <Cart04
              colors={{
                header: '#000',
                title: '#000',
                remove: '#000',
                productsBg: '#000',
                priceBefore: '#000',
                priceAfter: '#000',
                button: 'white',
                buttonBg: '#6D3B2A',
                orderSummary: '#000',
                orderSummaryBg: '#bf9fea40',
                orderSummaryBody: '#000',
                quantity: '#000',
                desc: '#bf9fea',
              }}
            />
          }
        />
        <Route
          path="/savedProducts"
          element={
            <ParentDepartmentPage
              type="favorite"
              card={{
                colors: {
                  titleColor: '#6D3B2A',
                  descColor: '#6D3B2A',
                  buttonBackgroundColor: '',
                  buttonTextColor: '#fff',
                },
                height: 300,
                width: 300,
                borderRadius: true,
                title: true,
                favorite: true,
                desc: true,
              }}
              search
            />
          }
        />
        <Route
          path="/privacyPolicy"
          element={<Privacy singleImage color="#6D3B2A" />}
        />
        <Route
          path="/contactUs"
          element={
            <ContactUs
              color={{
                borderColor: '#6D3B2A',
                textColor: '#6D3B2A',
                formBgColor: '#F5F2F1',
              }}
              button={{
                color: 'white',
                backgroundColor: '#6D3B2A',
              }}
              backgroundImag={img2}
            />
          }
        />

        <Route
          path="/profile"
          element={
            <Profile
              colors={{
                primaryBgColor: 'white',
                secondaryBgColor: '#F2EFEE',
                titleColor: '#6D3B2A',
                buttonColor: '#fff',
                buttonBgColor: '#6D3B2A',
                labelColor: '#6D3B2A',
                borderColor: '#6D3B2A',
              }}
              shapeProfile="vertical"
            />
          }
        />

        <Route
          path="/sign-in"
          element={
            <AuthenticationUser
              type="login"
              backgroundSvg={backgroundLogin}
              borderRadius
              backgroundBtn="#6D3B2A"
              color="#6D3B2A"
              borderInputColor="#6D3B2A"
              placeholderColor="#6D3B2A"
              iconColor="#6D3B2A"
            />
          }
        />
        <Route
          path="/register"
          element={
            <AuthenticationUser
              Buttons
              type="signUp"
              backgroundImag={backgroundLogin}
              logo={logo}
              backgroundBtn="#6D3B2A"
              color="#6D3B2A"
              borderInputColor="#6D3B2A"
              placeholderColor="#6D3B2A"
              iconColor="#6D3B2A"
            />
          }
        />
        <Route
          path="/forgetPassword"
          element={
            <ForgetPass
              borderInputColor="#6D3B2A"
              backgroundColorBtn="#6D3B2A"
              colorBtn="white"
              placeholderColor="#6D3B2A"
            />
          }
        />
        <Route
          path="/thankYou"
          element={
            <ThanksPage
              color={{
                titleColor: '#5B0888',
                subTitleColor: '#bf9fea',
                Btn: {
                  textColor: 'white',
                  bgColor: '#5B0888',
                },
              }}
            />
          }
        />
      </Routes>

      <Footer
        logo={logo}
        type="drajite"
        variant="form"
        bgColor={{ primary: '#6D3B2A ', secondary: '#fff' }}
        color={{ primary: '#fff', secondary: 'red' }}
        footerText={
          lang === 'en'
            ? '© 2023 All Rights Reserved.'
            : '© 2023 جميع الحقوق محفوظة.'
        }
        information={{
          address:
            'Jarir Marketing Company, King Khaled Street, Al Murabba District, Riyadh, KSA',
          email: 'jarir@gmail.com',
          phone: '+966500458116',
        }}
        social={{
          color: '#fff',
          bgColor: '#5B0888',
          facebook: 'https://www.facebook.com',
          instagram: 'https://www.instagram.com',
          linkedin: 'https://www.linkedin.com',
        }}
        map={{
          url: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1854.8072173905196!2d39.1349908!3d21.6009683!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3db4ed164d9a9%3A0xb6a4ba7216165791!2sSarri%20technology!5e0!3m2!1sen!2seg!4v1695652130557!5m2!1sen!2seg',
        }}
      />
    </BrowserRouter>
  )
}
