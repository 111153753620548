import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseUrl } from './baseUrl'

export const SectionApi = createApi({
  reducerPath: 'section',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'https://saritestsecond.online:8080/api/v1',
    // baseUrl: 'https://saritestsecond.online:8080/api/v1',
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
      return headers
    },
  }),
  tagTypes: ['section'],
  endpoints: (builder) => ({
    getAllSliders: builder.query({
      query: () => `/sections/?type=slider`,
      providesTags: ['section'],
    }),
  }),
})

export const { useGetAllSlidersQuery } = SectionApi
