import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { ContactUsType, propsContactUs03 } from './ContactData/ContactUsType'
import { Container, FormLabel, Typography } from '@mui/joy'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import ContactTextInput from './ContactData/ContactInput'
import Contact from './ContactData/ContactData'
import { useContactMutation } from '../../src/APIs/contactsApis'
import Banner from '../../HomePage/Banners/Banner'
import { toast } from 'react-toastify'
const ContactUs = (props: propsContactUs03) => {
  const [_, { language: lang, changeLanguage }] = useTranslation()
  const [contact] = useContactMutation()

  const { contactData, contactValidation } = Contact()

  const formik = useFormik<ContactUsType>({
    initialValues: contactData,
    validationSchema: contactValidation,
    onSubmit: (values: any) => {
      formik.resetForm()
      contact(values)
        .unwrap()
        .then((res: any) => {
          toast.success(res[`success_${lang}`])
        })
        .catch((err: any) => {
          toast.error(err[`error_${lang}`])
        })
    },
  })

  return (
    <Box
      sx={{
        direction: lang === 'en' ? 'rtl' : 'ltr',
        minHeight:"100vh"
      }}
    >
      <Box
        sx={{
          width:  { xs: '100%', md: '90%' },
          mx: 'auto',
          mb: 10,
          backgroundColor:  props.color?.backgroundColor?props.color?.backgroundColor:"#46454526",
          borderRadius:  lang === 'en'
          ? { xs: undefined, md: '0 150px 0px 150px' }
          : { xs: undefined, md: '150px 0 150px 0px' },
        
        }}
      >
        <Grid
          container
          sx={{
            // display: 'flex',
            // justifyContent: props.ADS ? undefined : 'center',
            // alignItems: props.ADS ? undefined : 'start',
            mt:  { xs: 0, md: '20px' },
            position: 'relative',
            // bgcolor:"blue",
            height:"100%"
           
          }}
        >
          
          <Grid
            item
            height={{xs:"70vh",md:"auto"}}
            xs={12}
            md={6}
            sx={{
           
              backgroundImage: props.backgroundImag
                ? `url(${props.backgroundImag})`
                : 'url(https://vitofryfilter.com/vendor/semantic/examples/assets/images/wireframe/image-square.png)',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              objectFit: 'fill',
              backgroundPosition: 'center',
              // position: 'relative',
              borderRadius:  lang === 'en'
                ? { xs: undefined, md: '0 150px 0px 0px' }
                : { xs: undefined, md: '150px 0 0 0' },
            }}
          >
         
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems:  'center',
              
            }}
          >
            <Container
              sx={{
                
               
                borderRadius: lang === 'en'
                  ? { xs: undefined, md: '0 0 0 150px' }
                  : { xs: undefined, md: '0px 0 150px 0px' },
                  py:2
                 
              }}
            >
         

              <Box
                sx={{
                  direction: lang === 'en' ? 'ltr' : 'rtl',
                  mt:  0,
                  
                }}
              >
                <form onSubmit={formik.handleSubmit} >
                  <Box >
                    <ContactTextInput
                      index={0}
                      value={formik.values.name}
                      name={'name'}
                      error={formik.errors.name}
                      touched={formik.touched.name}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      placeholder={lang === 'en' ? 'Name' : 'الاسم'}
                      raduis
                      background={ true}
                    color={props.color}
                    />
                  </Box>

                  <Box >
                    <ContactTextInput
                      index={0}
                      value={formik.values.email}
                      name={'email'}
                      error={formik.errors.email}
                      touched={formik.touched.email}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      placeholder={
                        lang === 'en' ? 'Email' : 'البريد الالكتروني'
                      }
                      raduis
                      background={ true}
                      color={props.color}
                    />
                  </Box>
                  <Box >
                    <ContactTextInput
                      index={0}
                      value={formik.values.phone}
                      name={'phone'}
                      error={formik.errors.phone}
                      touched={formik.touched.phone}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                      placeholder={lang === 'en' ? 'Phone' : 'الجوال'}
                      raduis
                      background={ true}
                      color={props.color}
                    />
                  </Box>

                  <Container
                   
                  >
                    <Box my={2} >
                     
                        <Box >
                          <FormLabel
                            sx={{
                              fontSize: '20px',
                              fontWeight: 'bold',
                              color: props.color?.textColor ? props.color.textColor : 'black',
                              my:2
                            }}
                          >
                            {lang === 'en' ? 'Contact Type' : 'نوع التواصل'}
                          </FormLabel>
                        </Box>
                   
                      <select
                        value={formik.values.contactType}
                        name="contactType"
                        style={{
                          backgroundColor: 'transparent',
                          width: '100%',
                          borderRadius: '50px',
                          padding: '25px',
                          border:
                            formik.errors.contactType &&
                            formik.touched.contactType
                              ? `2px solid red `
                              : `2px solid ${props.color?.borderColor ? props.color?.borderColor : 'black'}`,
                          fontSize: '20px',
                          color: formik.values.contactType ? "black" : '#333',
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="select one" hidden selected>
                          {lang === 'en'
                            ? 'Select Contact Type'
                            : 'أختار نوع التواصل'}
                        </option>
                        <option
                          value="complaints"
                          style={{
                            color: props.color?.textColor,
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                          {lang === 'en' ? 'Complaints' : 'الشكاوي'}
                        </option>
                        <option
                          value="suggestions"
                          style={{
                            color: props.color?.textColor,
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                          {lang === 'en' ? 'Suggestions' : 'الاقتراحات'}
                        </option>
                        <option
                          value="customerService"
                          style={{
                            color: props.color?.textColor,
                            fontSize: '16px',
                            fontWeight: 'bold',
                          }}
                        >
                          {lang === 'en' ? 'Customer Service' : 'خدمة العملاء'}
                        </option>
                      </select>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'red',
                        }}
                      >
                        {formik.errors.contactType && formik.touched.contactType
                          ? formik.errors.contactType
                          : undefined}
                      </Typography>
                    </Box>

                    <Box my={4}>
                    <Box >
                          <FormLabel
                            sx={{
                              fontSize: '20px',
                              fontWeight: 'bold',
                              color: props.color?.textColor ? props.color.textColor : 'black',
                              my:2
                            }}
                          >
                            {lang === 'en' ? 'Write your message' : 'اكتب رسالتك'}
                          </FormLabel>
                        </Box>
                      <textarea
                        value={formik.values.message}
                        name="message"
                        style={{
                          width: '100%',
                          padding: '12px 16px',
                          height: '200px',
                          backgroundColor: 'transparent ',

                          fontSize: '16px',

                          color: "black",

                          border:
                            formik.errors.message && formik.touched.message
                              ? '2px solid red'
                              : `2px solid ${props.color?.borderColor ? props.color?.borderColor : 'black'}`,
                          outline: 0,
                          borderRadius: '20px',
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'red',
                        }}
                      >
                        {formik.errors.message && formik.touched.message
                          ? formik.errors.message
                          : undefined}
                      </Typography>
                    </Box>
                    {/* Button */}
                    <Box
                      sx={{
                        
                        textAlign:'center',
                        mb: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: props.button?.backgroundColor
                            ? `${props.button?.backgroundColor} !important`
                            : '#000 !important',
                          color: props.button?.color
                            ? `${props.button?.color} !important`
                            : '#fff !important',
                          fontWeight: 'bold',
                          fontSize: { xs: '20px', md: '26px' },
                          transition: 'all 0.3s',
                          px: {
                            md: '60px',
                            xs: '55px',
                          },
                          py: '6px',
                          '&:active': {
                            transform: 'scale(0.9)',
                          },
                          borderRadius: '50px',
                        }}
                        type="submit"
                      >
                        {lang === 'en' ? 'Send' : 'ارسال'}
                      </Button>
                    </Box>
                  </Container>
                </form>
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default ContactUs
