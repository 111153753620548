import {
  Box,
  Button,
  Container,
  Rating,
  Stack,
  Typography,
} from '@mui/material'
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react'
// eslint-disable-next-line import/no-unresolved
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'swiper/css'
import {
  useAddRatingMutation,
  useGetSingleProductQuery,
} from '../src/APIs/ProductApis'
import { useAddToCartMutation } from '../src/APIs/cartApi'
import { imageBaseUrl } from '../src/App'
import { Attrs } from './Attrs'
import CustomPaymentType from './CustomPaymentType'
import { TCartColorsData, TsingleProductColors } from './SingleProductTypes'
export const TomoorSingleProduct = ({ colors }: TsingleProductColors) => {
  const { id } = useParams()
  const { data: product, isLoading, isError } = useGetSingleProductQuery(id)
  const [cartData, setCartData] = useState<TCartColorsData>({
    quantity: 1,
    qualities: [],
    id: id,
  })
  const [rate] = useAddRatingMutation()
  const updateQty = (method: string) => {
    method === '+'
      ? setCartData({ ...cartData, quantity: cartData.quantity + 1 })
      : cartData.quantity > 1 &&
        setCartData({ ...cartData, quantity: cartData.quantity - 1 })
  }
  const [, { language: lng }] = useTranslation()
  const [addToCart] = useAddToCartMutation()
  const [qImage, setQimages] = useState([])
  // useEffect(() => {
  //   cartData.qualities.map((q) => {
  //     console.log('q', q)
  //     product.data.qualitiesImages.map((qi) => {
  //       console.log('qi', qi)

  //       qi.qualities.map((qiq) => {
  //         console.log('qiq', qiq)

  //         if (qiq.value_en === q.value_en) {
  //           setQimages(qi.image)
  //         }
  //       })
  //     })
  //   })
  // }, [cartData, product?.data?.qualitiesImages])
  const { currentUser } = useSelector((state) => state) as any

  const handleRating = (productId: string, rating: number | null) => {
    if (!currentUser) {
      toast.error(lng === 'en' ? 'Login first' : 'سجل دخول أولاً')
    } else {
      rate({ productId, rating })
        .unwrap()
        .then((res) => toast.success(res.success_en))
        .catch((e) => toast.error(e.data.error_en))
    }
  }

  useEffect(() => {
    const images = cartData.qualities.map((q) => {
      const images = product?.data.qualitiesImages.map((qi: any) => {
        const isSelect = qi.qualities.some((qiq: any) => {
          return qiq.value_en === q.value_en
        })

        if (isSelect) {
          return qi.image
        }
        return null
      })

      return images.filter(Boolean)
    })
    const images2 = images.filter((i) => i.length > 0).map((i) => i[0])

    setQimages(images2 as any)
  }, [cartData])
  const extraPrice = cartData.qualities
    .map((q) => {
      const price = product?.data.qualities
        .find((p: any) => p.key_en === q.key_en)
        ?.values.find((v: any) => v.value_en === q.value_en)?.price

      return price
    })
    .reduce((a, b) => a + b, 0)

  return (
    <>
      {isLoading && !product && !isError && (
        <Container sx={{ minHeight: '60vh' }}>
          <span className="loader" />
        </Container>
      )}
      <Container sx={{ p: 5 }}>
        {!isLoading && product && (
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{
              justifyContent: 'space-between',
              height: { xs: 'auto', md: 'auto' },
              gap: '5%',
              my: 5,
              direction: lng === 'en' ? 'ltr' : 'rtl',
            }}
          >
            <Stack
              sx={{
                width: { xs: '100%', md: '47%' },
                height: '100%',
                gap: 5,
              }}
            >
              {
                <Box
                  component={Swiper}
                  sx={{ width: { xs: '100%', md: '47%' }, height: 350 }}
                >
                  {qImage.length > 0
                    ? qImage.map((img: string) => (
                        <SwiperSlide key={img}>
                          <Box
                            sx={{
                              background: `url("${
                                imageBaseUrl + img
                              }") center center`,
                              backgroundSize: ' contain',
                              backgroundRepeat: 'no-repeat',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </SwiperSlide>
                      ))
                    : product?.data?.images.map((img: string) => (
                        <SwiperSlide key={img}>
                          <Box
                            sx={{
                              background: `url("${
                                imageBaseUrl + img
                              }") center center`,
                              backgroundSize: ' contain',
                              backgroundRepeat: 'no-repeat',
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </SwiperSlide>
                      ))}
                </Box>
              }
              {/* {qImage.length < 1 ? (
                <Box
                  component={Swiper}
                  sx={{ width: { xs: '100%', md: '47%' }, height: 350 }}
                >
                  {product?.data?.images.map((img: string) => (
                    <SwiperSlide key={img}>
                      <Box
                        sx={{
                          background: `url("${
                            imageBaseUrl + img
                          }") center center`,
                          backgroundSize: ' contain',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    height: '50vh',
                    mx: 'auto',
                    fontSize: '3rem',
                    color: 'red',
                    background: `url("${imageBaseUrl + qImage}")`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              )} */}
              <Box
                sx={{
                  color: colors.descColor,
                  mx: { xs: 1, md: 5 },
                  wordBreak: 'break-word',
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    product?.data[`description_${lng === 'en' ? 'en' : 'ar'}`],
                }}
              />
            </Stack>

            <Stack
              sx={{
                width: { xs: '100%', md: '47%' },
                gap: 2,
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: 'bold', color: colors.titleColor }}
              >
                {product.data[`title_${lng === 'en' ? 'en' : 'ar'}`]}
              </Typography>

              <Typography
                sx={{
                  color: colors.priceBeforeColor,
                  textDecoration:
                    product.data.priceAfterDiscount > 0
                      ? 'line-through'
                      : 'none',
                  fontSize:
                    product.data.priceAfterDiscount > 0 ? '0.7rem' : 'initial',
                }}
              >
                {product.data.priceBeforeDiscount + extraPrice}{' '}
                {lng === 'en' ? 'SAR' : 'ر.س'}
              </Typography>
              {product.data.priceAfterDiscount > 0 && (
                <Typography sx={{ color: colors.priceAfterColor }}>
                  {product.data.priceAfterDiscount + extraPrice}{' '}
                  {lng === 'en' ? 'SAR' : 'ر.س'}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: 'fit-content',
                  alignItems: 'flex-start',
                }}
              >
                <Rating
                  value={product.data.rating}
                  sx={{ direction: 'ltr' }}
                  onChange={(_, newValue) =>
                    handleRating(product.data._id, newValue)
                  }
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: 'fit-content',
                  alignItems: 'flex-start',
                }}
              >
                {product.data.qualities.map(
                  (quality: {
                    key_en: string
                    key_ar: string
                    values: { value_en: string; value_ar: string }[]
                    _id: string
                  }) => (
                    <Attrs
                      key={quality._id}
                      colors={{
                        attrKeyColor: colors.attrKeyColor,
                        attrValueColor: colors.attrValueColor,
                        attrValueBgColor: colors.attrValueBgColor,
                        attrValueBorderColor: colors.attrValueBorderColor,
                      }}
                      attr={quality}
                      setCartData={setCartData}
                      cartData={cartData}
                    />
                  )
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: 'fit-content',
                  alignItems: 'flex-start',
                  direction: lng === 'en' ? 'rtl' : 'ltr',
                }}
              >
                <CustomPaymentType lang={lng} type={product.data.paymentType} />
              </Box>

              <Stack gap={1} direction={'row'}>
                <Typography variant="body1">
                  {lng === 'en' ? 'Availability: ' : 'التوفر: '}
                </Typography>
                <Typography variant="body1">
                  {product.data.quantity > 0
                    ? lng === 'en'
                      ? 'In stock'
                      : 'متوفر'
                    : lng === 'en'
                    ? 'Out of stock'
                    : 'غير متوفر'}
                </Typography>
              </Stack>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                sx={{ gap: 3, justifyContent: 'start', alignItems: 'center' }}
              >
                <Stack
                  direction={'row'}
                  sx={{
                    border: '1px solid black',
                    borderRadius: 1,
                    alignItems: 'center',
                    my: 1,
                    width: 'fit-content',
                    py: 0.5,
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => updateQty('-')}
                    disabled={cartData.quantity === 1}
                    sx={{
                      color: 'black',
                      minWidth: 40,
                    }}
                  >
                    -
                  </Button>
                  <Typography>{cartData.quantity}</Typography>
                  <Button
                    size="small"
                    onClick={() => updateQty('+')}
                    sx={{
                      color: 'black !important',
                      minWidth: 45,
                    }}
                  >
                    +
                  </Button>
                </Stack>
                <Button
                  variant="contained"
                  sx={{
                    width: 'fit-content',
                    px: 2,
                    py: 1,
                    color: colors.buttonColor,
                    bgcolor: `${colors.buttonBgColor} !important`,
                  }}
                  onClick={() => {
                    const qualities =
                      cartData.qualities.length > 0
                        ? {
                            qualities: cartData.qualities,
                          }
                        : {}
                    addToCart({
                      quantity: cartData.quantity,
                      id: cartData.id,
                      ...qualities,
                    })
                      .unwrap()
                      .then((res) =>
                        toast.success(
                          res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                        )
                      )
                      .catch((e) =>
                        toast.error(
                          e.data[`error_${lng === 'en' ? 'en' : 'ar'}`]
                        )
                      )
                  }}
                >
                  {lng === 'en' ? 'Add to cart' : 'أضف إلى سلة التسوق'}
                </Button>
              </Stack>
              <Stack gap={1} direction={'row'}>
                <Typography variant="body1">
                  {lng === 'en' ? 'Weight: ' : 'الوزن: '}
                </Typography>
                <Typography variant="body1">{product.data.weight} </Typography>
                <Typography variant="body1">
                  {lng === 'en' ? 'grams' : 'جرام'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Container>
    </>
  )
}
