import { Box, Typography } from '@mui/material'
import React from 'react'
import data from '../data.json'
import { heroBackImgProps } from './HeroTypes'
import { useTranslation } from 'react-i18next'
import { useGetAllSlidersQuery } from '../../src/APIs/SectionApis'

//Hero1 is the first hero section that will contain the background image and the text
const Hero1 = (props: heroBackImgProps) => {
  const [_, { language: lang }] = useTranslation()
  const { data, isError, isLoading, error } = useGetAllSlidersQuery(undefined)
  const dataHero = data?.data.length - 1 //last item in array

  const colors = {
    primary: 'white',
    secondary: '#e3e3e3',
    backgroundPrimary: '#b0b4c5',
    backgroundSecondary: '#d2d9d5',
  }

  return (
    <Box
      minHeight={'100vh'}
      sx={{
        direction: lang === 'ar' ? 'rtl' : 'ltr',
      }}
    >
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${lang}`]}
          </Typography>
        </Box>
      )}

      {!isLoading && !isError && (
        <Box
          sx={{
            height: '100vh',
            width: '100%',
            backgroundImage: `linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0.1)),url(${props.backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
          }}
        >
          {/* Title and SubTitle */}
          <Box
            width={{ xs: 0.7, sm: 0.5, md: 0.4 }}
            sx={{
              position: 'absolute',
              top: '45%',
              left:
                lang === 'en'
                  ? { xs: '50%', lg: '30%' }
                  : { xs: '50%', lg: '70%' },
              transform: 'translate(-50%,-50%)',
            }}
          >
            {/* Title */}
            <Typography
              variant="h1"
              sx={{
                mb: 2,
                color: props.color?.titleColor
                  ? props.color?.titleColor
                  : colors.primary,
                fontWeight: 'bold',
                fontSize: {
                  xs: '1.8rem',
                  sm: '2.3rem',
                  md: '2.5rem',
                  lg: '3.2rem',
                  xl: '4rem',
                },
              }}
            >
              {data?.data[dataHero][`title_${lang}`]}
            </Typography>

            {/* sub title */}
            <Typography
              variant="h6"
              sx={{
                color: props.color?.subTitleColor
                  ? props.color?.subTitleColor
                  : colors.primary,
                fontSize: {
                  xs: '1rem',
                  sm: '1.2rem',
                  md: '1.3rem',
                },
                wordBreak: 'break-word',
              }}
            >
              {data?.data[dataHero][`description_${lang}`]}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Hero1
