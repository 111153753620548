import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useGetAboutUsDataQuery } from '../src/APIs/aboutUsApi'
import { imageBaseUrl } from '../src/App'
import { AboutColors, AboutUsTypes } from './AboutTypes'

function splitTextIntoSections(text = '', numberOfSections = 1) {
  // Split the text into an array of HTML tag and non-tag segments
  const segments = text.split(/(<[^>]*>)|\n+/).filter(Boolean)

  // Calculate the desired number of segments per section
  const segmentsPerSection = Math.ceil(segments.length / numberOfSections)

  // Initialize an array to store the sections
  const sections = []

  // Iterate through the segments and group them into sections
  for (let i = 0; i < segments.length; i += segmentsPerSection) {
    const section = segments.slice(i, i + segmentsPerSection).join('')
    sections.push(section)
  }

  return sections
}

const Card: React.FC<{
  data: {
    alignment: 'horizontal'
    title_en: string
    title_ar: string
    description_en: string
    description_ar: string
    image: string
    type: 'aboutus'
  }
  colors: AboutColors
  image: string
  index: number
  length: number
}> = ({ data, index, length, image, colors }) => {
  const [, { language }] = useTranslation()
  const handleDesc = () => {
    return splitTextIntoSections(
      language === 'en' ? data?.description_en : data?.description_ar,
      length
    )[index]
  }

  const elementRef = useRef<any>(null)

  // State to store the height of the element
  const [elementHeight, setElementHeight] = useState(null)

  // Function to get and set the height of the element
  const getElementHeight = () => {
    if (elementRef.current) {
      const height = elementRef.current?.clientHeight || 0
      setElementHeight(height)
    }
  }

  // Call the function when the component mounts and whenever it updates
  useEffect(() => {
    getElementHeight()
  })

  useEffect(() => {
    // Check if the text size changes when the window is resized

    window.addEventListener('resize', getElementHeight)

    return () => {
      window.removeEventListener('resize', getElementHeight)
    }
  }, [])

  return (
    <Box
      dir={language === 'en' ? 'ltr' : 'rtl'}
      sx={{ bgcolor: index % 2 === 0 ? colors.bgColor1 : colors.bgColor2 }}
    >
      <Grid
        container
        sx={{
          //  reverse order index % 2 === 0
          flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
        }}
      >
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 2 }} ref={elementRef}>
            <Typography
              variant="body1"
              sx={{
                color: colors.descColor,
                textAlign: language === 'en' ? 'left' : 'right',
                '& ul': { textAlign: language === 'en' ? 'left' : 'right' },
                '& ol': { textAlign: language === 'en' ? 'left' : 'right' },
              }}
              dangerouslySetInnerHTML={{
                __html: handleDesc(),
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            height: elementHeight,
          }}
        >
          <Box
            component="img"
            sx={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
            src={image}
            alt={language === 'en' ? data?.title_en : data?.title_ar}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export const AboutUs3 = ({ colors, imgs }: AboutUsTypes) => {
  const [, { language: lang }] = useTranslation()
  const { data, isLoading } = useGetAboutUsDataQuery(undefined)
  const aboutData = data?.data[0]

  if (isLoading) {
    return (
      <Container sx={{ minHeight: '100vh' }}>
        <Typography variant="h3" sx={{ color: '#000' }}>
          <div className="loader" />
        </Typography>
      </Container>
    )
  }

  if (!aboutData)
    return (
      <Container sx={{ minHeight: '100vh' }}>
        <Typography
          variant="h3"
          sx={{ color: colors.descColor, textAlign: 'center' }}
        >
          {lang === 'en' ? 'No Data' : 'لا يوجد بيانات'}
        </Typography>
      </Container>
    )

  return (
    <Stack sx={{ direction: lang === 'en' ? 'ltr' : 'rtl' }}>
      <Container>
        <Typography variant="h3" sx={{ color: colors?.titleColor }}>
          {lang === 'en' ? aboutData?.title_en : aboutData?.title_ar}
        </Typography>
      </Container>
      {imgs?.map((img, index) => (
        <Card
          length={imgs.length}
          index={index}
          key={index}
          data={aboutData}
          colors={colors}
          image={index === 0 ? imageBaseUrl + aboutData?.image : img}
        />
      ))}
    </Stack>
  )
}
