import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import { NavTypes, NavItems } from './NavTypes'
import { useTranslation } from 'react-i18next'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useLocation, useNavigate } from 'react-router-dom'
interface NavMenuProps extends NavTypes {
  item: NavItems
  handleDrawerToggle?: () => void
  handleClose?: () => void
}
export default function NavNestedCategoryMenu(props: NavMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [_, { language: lng }] = useTranslation()
  const handleClickCategMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const handleCloseCategMenu = () => {
    setAnchorEl(null)
  }
  console.log('props', props)
  return (
    <Box
      sx={{
        width: {
          lg: props.direction === 'vertical' ? 1 : 'auto',
          xs: 1,
        },
        direction: lng === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Button
        sx={{
          mx: {
            lg: 0,
            xs: 'auto',
          },
          width: {
            lg: props.direction === 'vertical' ? 1 : 170,
            xs: 1,
          },
          display: 'flex',
          justifyContent: {
            lg: 'center',
            xs: 'space-between',
          },
          bgcolor: `${
            pathname.includes(`/departments/${props.item.id}`)
              ? props.colors.activeMenuItemBgColor
              : props.colors.menuItemBgColor
          } !important`,
          color: pathname.includes(`/departments/${props.item.id}`)
            ? props.colors.activeMenuItemColor
            : props.colors.menuItemColor,
          cursor: 'pointer',

          textTransform: 'capitalize',
          p: props.blockStyle ? '.7rem 1rem' : '8px 10px',
          gap: '7px',
          mb: '5px',
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickCategMenu}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 1,
          }}
        >
          <span>{props.item[`title_${lng === 'en' ? 'en' : 'ar'}`]}</span>
          <ArrowDropDownIcon
            sx={{
              transition: 'all 0.4s',
              color: pathname.includes(`/departments/${props.item.id}`)
                ? props.colors.activeMenuItemColor
                : props.colors.menuItemColor,
              transform: open
                ? `rotate(${lng === 'en' ? '-90deg' : '90deg'})`
                : 'rotatex(0)',
            }}
          />
        </Stack>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseCategMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          horizontal: lng !== 'en' ? 'left' : 'right',
          vertical: 'center',
        }}
        transformOrigin={{
          horizontal: lng === 'en' ? 'left' : 'right',
          vertical: 'top',
        }}
        sx={{
          '.MuiList-root': {
            width: 190,
          },
          '.MuiPaper-root': {
            bgcolor: props.colors.menuBgColor,
          },
        }}
      >
        {props.item.subs.map((sub: any) => (
          <MenuItem
            key={sub.id}
            sx={{
              bgcolor: `${
                pathname.includes(`/departments/${props.item.id}/${sub.id}`)
                  ? props.colors.activeMenuItemBgColor
                  : props.colors.menuItemBgColor
              } !important`,
              color: pathname.includes(
                `/departments/${props.item.id}/${sub.id}`
              )
                ? props.colors.activeMenuItemColor
                : props.colors.menuItemColor,
              mb: '5px',
              textAlign: 'center !important',
              justifyContent: lng === 'en' ? 'flex-start' : 'flex-end',
            }}
            onClick={() => {
              handleCloseCategMenu()
              navigate(`/departments/${props.item.id}/${sub.id}`)
              if (props.handleDrawerToggle) {
                props.handleDrawerToggle()
              }
              if (props.handleClose) {
                props.handleClose()
              }
            }}
          >
            {sub[`title_${lng}`]}
          </MenuItem>
        ))}
        {props.item.subs[1] && (
          <MenuItem
            sx={{
              bgcolor: `${
                pathname === `/departments/${props.item.id}`
                  ? props.colors.activeMenuItemBgColor
                  : props.colors.menuItemBgColor
              } !important`,
              color:
                pathname === `/departments/${props.item.id}`
                  ? props.colors.activeMenuItemColor
                  : props.colors.menuItemColor,
              justifyContent: lng === 'en' ? 'flex-start' : 'flex-end',
            }}
            onClick={() => {
              handleCloseCategMenu()
              navigate(`/departments/${props.item.id}`)
              if (props.handleDrawerToggle) {
                props.handleDrawerToggle()
              }
              if (props.handleClose) {
                props.handleClose()
              }
            }}
          >
            {lng === 'en' ? 'All sub categories' : 'جميع الأقسام الفرعية'}
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
