import {
  Box,
  Grid,
  Typography,
  Stack,
  ButtonBase,
  Radio,
  TextField,
  InputBase,
  InputAdornment,
} from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Person2Icon from '@mui/icons-material/Person2'
import PhoneIcon from '@mui/icons-material/Phone'
import MailIcon from '@mui/icons-material/Mail'
import HttpsIcon from '@mui/icons-material/Https'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import * as yup from 'yup'
import { Input } from '@mui/joy'
import { propsAuth, validationSchemaType } from './AuthenticationUserType'
import { useLoginMutation, useRegisterMutation } from '../src/APIs/UserApis'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../src/APIs/userSlice'

const AuthenticationUser = (props: propsAuth) => {
  const [selectedValue, setSelectedValue] = useState('email')
  const [, { language: lang }] = useTranslation()
  const [passwordType, setPasswordType] = useState(true)
  const [register] = useRegisterMutation()
  const [login] = useLoginMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [type, setType] = useState(props.type)
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value)
    formik.resetForm()
    formik.setFieldValue('registrationType', event.target.value)
  }
  const handelTypeAuth = (typeAuth: string) => {
    formik.resetForm()
    setType(typeAuth)
  }

  const validationSchema = yup.object<validationSchemaType>({
    registrationType: yup.string().required(),
    name: yup.string(),
    phone: yup.string().when('registrationType', {
      is: (val: string) => (val === 'phone' ? true : false),
      then: () =>
        yup
          .string()
          .matches(
            /^\+[0-9]+$/,
            lang === 'en'
              ? 'Phone must start with country code and only numbers'
              : 'يجب أن يبدأ الهاتف برمز البلد وأرقام فقط'
          )
          .required(lang === 'en' ? 'Phone is required*' : '*رقم الهاتف مطلوب'),
      otherwise: () => yup.number().notRequired(),
    }),
    email: yup.string().when('registrationType', {
      is: (val: string) => (val === 'email' ? true : false),
      then: () =>
        yup
          .string()
          .email(lang === 'en' ? 'Invalid email' : 'بريد إلكتروني خاطئ')
          .required(
            lang === 'en' ? 'Email is required*' : '*البريد الإلكتروني مطلوب'
          ),
      otherwise: () => yup.string().notRequired(),
    }),
    password: yup.string().when('registrationType', {
      is: (val: string) => (val === 'email' ? true : false),
      then: () =>
        yup
          .string()
          .required(
            lang === 'en' ? 'Password is required*' : '*كلمة المرور مطلوبة'
          ),
    }),
  })
  const initialValues: validationSchemaType = {
    name: '',
    email: '',
    phone: '',
    password: '',
    registrationType: selectedValue,
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => {
      let data = { ...values }
      // signUp
      if (type === 'signUp') {
        data.registrationType === 'email'
          ? delete data.phone
          : (delete data.email, delete data.password)
        data.name?.length === 0 && delete data.name

        if (values.registrationType === 'phone') {
          return // verifycode
        } else {
          register(data)
            .unwrap()
            .then((res) => {
              localStorage.setItem('token', res.token)
              toast.success(res[`success_${lang}`])
              dispatch(setCurrentUser(data))
              navigate('/')
            })
            .catch((err) => {
              toast.error(err.data[`error_${lang}`])
            })
        }
      }
      // login
      else {
        data.registrationType === 'email'
          ? delete data.phone
          : (delete data.email, delete data.password)
        delete data.name
        if (data.registrationType === 'phone') {
          return // verifycode
        } else {
          login(data)
            .unwrap()
            .then((res) => {
              localStorage.setItem('token', res.token)
              toast.success(res[`success_${lang}`])
              // handleDispatchedData()

              dispatch(setCurrentUser(data))
              navigate('/')
            })
            .catch((err) => {
              toast.error(err.data[`error_${lang}`])
            })
        }
      }
    },
  })

  return (
    <Box>
      <Grid
        container
        sx={{
          direction: lang === 'en' ? 'ltr' : 'rtl',
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            minHeight: {
              md: '90vh',
              xs: 'auto',
            },
            width: '100%',
          }}
        >
          <Box
            mt={props.Buttons?{ xs: 4, md: 25 }:{xs: 4, md: 10 }}
            sx={{
              width: { xs: '100%', sm: '80%' },
              mx: 'auto',
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Stack
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: '20px 0',
                  mt: { xs: 6, md: '0' },
                  
                }}
              >
                {!props.Buttons && !props.Radio&&(
                  <Stack
                    direction={'row-reverse'}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: { xs: '20px', lg: '50px' },
                      width: '100%',
                      mt: { xs: 5, md: '0' },
                    }}
                  >
                    <Box
                      sx={{
                        px: 4,
                        py: 1.5,
                        borderRadius: props.borderRadius ? '50px' : '10px',
                        border: `2px solid ${
                          props.color ? props.color : `2px solid black`
                        }`,
                        backgroundColor:
                          type === 'signUp' ? props.color : 'transparent',

                        cursor: 'pointer',
                      }}
                      onClick={() => handelTypeAuth('signUp')}
                    >
                      <Typography
                        variant="h4"
                        textAlign="center"
                        sx={{
                          fontSize: {
                            xl: '30px',
                            lg: '25px',
                            sm: '20px',
                            xs: '18px',
                          },
                          fontWeight: 'bolder',
                          color:
                            type === 'signUp'
                              ? '#fff'
                              : props.color
                              ? props.color
                              : 'gray',
                        }}
                      >
                        {lang === 'en' ? 'Create an account' : 'إنشاء حساب'}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        px: 4,
                        py: 1.5,
                        borderRadius: props.borderRadius ? '50px' : '10px',
                        border: `2px solid ${
                          props.color ? props.color : `2px solid black`
                        }`,
                        backgroundColor:
                          type === 'login' ? props.color : 'transparent',
                        cursor: 'pointer',
                      }}
                      onClick={() => handelTypeAuth('login')}
                    >
                      <Typography
                        variant="h4"
                        textAlign="center"
                        sx={{
                          fontSize: {
                            xl: '30px',
                            lg: '25px',
                            sm: '20px',
                            xs: '18px',
                          },
                          fontWeight: 'bolder',
                          color:
                            type === 'login'
                              ? '#fff'
                              : props.color
                              ? props.color
                              : 'gray',
                        }}
                      >
                        {lang === 'en' ? 'Login' : ' الدخول'}
                      </Typography>
                    </Box>
                  </Stack>
                )}

                <Box
                  sx={{
                    py: '50px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                    width: {
                      xl: 550,
                      lg: 425,
                      md: 0.75,
                      xs: 0.92,
                    },
                  }}
                >
                  {props.Radio && (
                    <Box>
                      {/* <Typography
                        style={{
                          fontWeight: 'bold',
                          fontSize: '20px',
                          color: props.color ? props.color : 'gray',
                        }}
                      >
                        {props.type === 'signUp'
                          ? lang === 'en'
                            ? 'I want to register with'
                            : 'أريد التسجيل بواسطة'
                          : lang === 'en'
                          ? 'I want to login with'
                          : 'أريد تسجيل الدخول بواسطة'}
                      </Typography> */}

                      <Stack
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          flexDirection: 'row',
                          gap: '5px',
                        }}
                      >
                        <Radio
                          checked={selectedValue === 'email'}
                          onChange={handleChange}
                          value="email"
                          name="radio-buttons"
                          id="email-radio"
                          sx={{
                            fontWeight: 'bold',
                            mt: '5px',
                          }}
                        />
                        <label
                          htmlFor="email-radio"
                          style={{
                            fontWeight: 'bold',
                            marginTop: '5px',
                            fontSize: '20px',
                            cursor: 'pointer',
                            color: props.color ? props.color : 'gray',
                          }}
                        >
                          {lang === 'en' ? 'Email' : 'البريد الإلكتروني'}
                        </label>
                        <Radio
                          onChange={handleChange}
                          value="phone"
                          checked={selectedValue === 'phone'}
                          name="radio-buttons"
                          id="phone-radio"
                          sx={{
                            fontWeight: 'bold',
                            mt: '5px',
                          }}
                        />
                        <label
                          htmlFor="phone-radio"
                          style={{
                            fontWeight: 'bold',
                            marginTop: '5px',
                            fontSize: '20px',
                            cursor: 'pointer',
                            color: props.color ? props.color : 'gray',
                          }}
                        >
                          {lang === 'en' ? 'Phone' : 'رقم الهاتف'}
                        </label>
                      </Stack>
                    </Box>
                  )}

                  <Box>
                    {type == 'signUp' && (
                      <Box>
                        {/* Name */}
                        <InputBase
                          startAdornment={
                            <Person2Icon
                              sx={{
                                color: props.iconColor
                                  ? props.iconColor
                                  : 'black',
                                  mx:2,
                                  textAlign:lang==="en"?"left":"right"
                                 
                              }}
                            />
                          }
                          placeholder={lang === 'en' ? 'Name' : 'الاسم'}
                          type={'text'}
                          sx={{
                            p: '15px 6px',
                            border: `2px solid ${
                              props.borderInputColor
                                ? `${props.borderInputColor} !important`
                                : `2px solid black !important`
                            }`,
                            backgroundColor: 'transparent !important',
                            borderRadius: props.borderRadius ? '50px' : '0',

                            '& > .css-17ewsm8-JoyInput-root': {
                              inputFocusedHighlight: 'black !important',
                            },
                            '& input::placeholder': {
                              color: props.placeholderColor
                                ? props.placeholderColor
                                : 'gray',
                            },
                            width: props.Buttons
                              ? { xs: '100%', md: '90%' }
                              : '100%',
                            mt: props.Buttons ? { xs: 2, md: 0 } : 0,
                          }}
                          name="name"
                          value={formik.values.name}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Box>
                    )}

                    {/* Phone */}
                    <Box
                      sx={{
                        display:
                          selectedValue === 'email' ||
                          (!props.Radio && selectedValue === 'phone')
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <InputBase
                        disabled={
                          formik.values.email !== '' ||
                          formik.values.password !== ''
                        }
                        
                          startAdornment={

                            <InputAdornment position="start">
                              <PhoneIcon
                                sx={{
                                  color:
                                    formik.values.email !== '' ||
                                    formik.values.password !== ''
                                      ? 'gray'
                                      : props.iconColor
                                      ? props.iconColor
                                      : 'black',
                                      mx:2
                                }}
                              />
                            </InputAdornment>
                          }
                        
                       
                        placeholder={lang === 'en' ? 'Phone' : 'الهاتف'}
                        type={'text'}
                        sx={{
                          p: '15px 0px',
                          borderRadius: props.borderRadius ? '50px' : '0',
                          width: props.Buttons
                            ? { xs: '100%', md: '90%' }
                            : '100%',
                          mt: '20px',
                          border: `2px solid ${
                            props.borderInputColor
                              ? `${props.borderInputColor} !important`
                              : `2px solid black !important`
                          }`,
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            opacity: 0,
                          },
                          '& input::placeholder': {
                            color: props.placeholderColor
                              ? props.placeholderColor
                              : 'gray',
                          },
                        }}
                        name="phone"
                        value={formik.values.phone}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onClick={() => {
                          formik.setFieldValue('registrationType', 'phone')
                        }}
                      />
                      {formik.errors.phone && formik.touched.phone && (
                        <Typography
                          fontWeight={'bold'}
                          fontSize={13}
                          color="red"
                          sx={{}}
                        >
                          {formik.errors.phone}
                        </Typography>
                      )}
                    </Box>

                    {/* Email */}

                    <Box
                      sx={{
                        display:
                          selectedValue === 'phone' && props.Radio
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <InputBase
                        disabled={
                          selectedValue === 'phone' ||
                          formik.values.phone !== ''
                            ? true
                            : false
                        }
                       
                       
                          startAdornment={
                            
                             <InputAdornment position="start">
                               <MailIcon
                                 sx={{
                                   color:
                                     selectedValue === 'phone' ||
                                     formik.values.phone !== ''
                                       ? 'gray'
                                       : props.iconColor
                                       ? props.iconColor
                                       : 'black',
                                       mx:2
                                 }}
                               />
                             </InputAdornment>
                          
                          }
                            
                        
                        placeholder={
                          lang === 'en' ? 'Email' : 'البريد الإلكتروني'
                        }
                        type={'text'}
                        sx={{
                          borderRadius: props.borderRadius
                            ? '50px !important'
                            : '0 !important',
                          mt: '20px',
                          p: '15px 0px',
                          width: props.Buttons
                            ? { xs: '100%', md: '90%' }
                            : '100%',
                          border:
                            formik.values.phone !== ''
                              ? '2px solid gray !important'
                              : `2px solid ${
                                  props.borderInputColor
                                    ? props.borderInputColor
                                    : `2px solid black`
                                }!important`,
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            opacity: 0,
                            
                          },
                          '& input::placeholder': {
                            color: props.placeholderColor
                              ? props.placeholderColor
                              : 'gray',
                          },
                          "& fieldset": {
                            border: "1px solid red",
                          }
                        }}
                        name="email"
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onClick={() => {
                          formik.setFieldValue('registrationType', 'email')
                        }}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <Typography
                          fontWeight={'bold'}
                          fontSize={13}
                          //   variant="p"
                          color="red"
                          sx={
                            {
                              // fontFamily: publicFontFamily,
                            }
                          }
                        >
                          {formik.errors.email}
                        </Typography>
                      )}
                    </Box>

                    {/* Password */}
                    <Box
                      sx={{
                        display:
                          selectedValue === 'phone' && props.Radio
                            ? 'none'
                            : 'block',
                      }}
                    >
                      <InputBase
                        name="password"
                        value={formik.values.password}
                        disabled={
                          selectedValue === 'phone' ||
                          formik.values.phone !== ''
                        }
                        placeholder={lang === 'en' ? 'Password' : 'كلمة المرور'}
                        type={passwordType ? 'password' : 'text'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        onClick={() => {
                          formik.setFieldValue('registrationType', 'email')
                        }}
                        
                          startAdornment={

                            <InputAdornment position="start">
                              <HttpsIcon
                                sx={{
                                  color:
                                    selectedValue === 'phone' ||
                                    formik.values.phone !== ''
                                      ? 'gray'
                                      : props.iconColor
                                      ? props.iconColor
                                      : 'black',
                                      mx:2
                                }}
                              />
                            </InputAdornment>
                          }
                        
                          endAdornment={

                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPasswordType(!passwordType)}
                                // onMouseDown={handleMouseDownPassword}

                                edge="end"
                              >
                                {passwordType ? (
                                  <VisibilityOff
                                    sx={{
                                      color:
                                        selectedValue === 'phone' ||
                                        formik.values.phone !== ''
                                          ? 'gray'
                                          : props.iconColor
                                          ? props.iconColor
                                          : 'black',
                                          mx:lang==="en"?2:1
                                    }}
                                  />
                                ) : (
                                  <Visibility
                                    sx={{
                                      color:
                                        selectedValue === 'phone' ||
                                        formik.values.phone !== ''
                                          ? 'gray'
                                          : props.iconColor
                                          ? props.iconColor
                                          : 'black',
                                    }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        
                         
                        
                        sx={{
                          p: '15px 0px',
                          width: props.Buttons
                            ? { xs: '100%', md: '90%' }
                            : '100%',
                          borderRadius: props.borderRadius ? '50px' : '0',
                          mt: '20px',
                          border:
                            selectedValue === 'phone' ||
                            formik.values.phone !== ''
                              ? '2px solid gray'
                              : `2px solid ${
                                  props.borderInputColor
                                    ? props.borderInputColor
                                    : `2px solid black`
                                }`,
                          '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                            opacity: 0,
                          },
                          '.css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root':
                            {
                              px: lang === 'en' ? undefined : '0',
                            },
                          ' .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input':
                            {
                              p: lang === 'en' ? undefined : '16.5px 14px',
                            },
                          '& input::placeholder': {
                            color: props.placeholderColor
                              ? props.placeholderColor
                              : 'gray',
                          },
                        }}
                      />

                      {formik.errors.password && formik.touched.password && (
                        <Typography
                          fontWeight={'bold'}
                          fontSize={13}
                          color="red"
                        >
                          {formik.errors.password}
                        </Typography>
                      )}
                    </Box>

                    {type === 'login' &&
                      formik.values.registrationType !== 'phone' && (
                        <Box mt={2}>
                          <Typography
                            onClick={() => navigate('/forgetPassword')}
                            sx={{
                              cursor: 'pointer',
                              display: 'inline',
                              borderBottom: `1px solid ${props.iconColor}`,
                              color: props.iconColor
                            }}
                          >
                            {lang === 'en'
                              ? 'Forget password ?'
                              : 'نسيت كلمة المرور ؟'}
                          </Typography>
                        </Box>
                      )}

                    {/* if unUsed radio select */}
                    {!props.Radio && (
                      <Box>
                        <Box textAlign={'center'} position={'relative'}>
                          <Typography
                            sx={{
                              '::after': {
                                content: "''",
                                width: props.Buttons ? '36%' : lang==="en"?'40%':"45%",
                                height: '1px',
                                backgroundColor: props.iconColor
                                  ? props.iconColor
                                  : 'black',
                                position: 'absolute',
                                top: '50%',
                                left:
                                  lang === 'en'
                                    ? '0'
                                    : props.Buttons?{ xs: '0px', md: '40px' }:{ xs: '0px', md: '5px' },
                                transform: 'translateY(-50%)',
                              },
                              '::before': {
                                content: "''",
                                width: props.Buttons ? '35%' : '42%',
                                height: '1px',
                                backgroundColor: props.iconColor
                                  ? props.iconColor
                                  : 'black',
                                position: 'absolute',
                                top: '50%',
                                left:
                                  lang === 'en'
                                    ? { xs: '60%', md: '55%' }
                                    : props.Buttons?'65%':"60%",
                                transform: 'translateY(-50%)',
                              },
                              fontSize: {
                                xl: '30px',
                                md: '25px',

                                xs: '20px',
                              },
                              fontWeight: 'bold',
                              mt: '20px',
                              ml:
                                lang === 'en'
                                  ? { xs: 0, md:props.Buttons? -5:-2 }
                                  : { xs: 0, md: 5 },
                              color: props.iconColor ? props.iconColor : 'gray',
                            }}
                          >
                            {lang === 'en' ? 'OR' : 'أو'}
                          </Typography>
                        </Box>
                        <Box>
                          <InputBase
                            disabled={
                              formik.values.email !== '' ||
                              formik.values.password !== ''
                            }
                            
                              startAdornment={

                                <InputAdornment position="start">
                                  <PhoneIcon
                                    sx={{
                                      color:
                                        formik.values.email !== '' ||
                                        formik.values.password !== ''
                                          ? 'gray'
                                          : props.iconColor
                                          ? props.iconColor
                                          : 'black',
                                          mx:2
                                    }}
                                  />
                                </InputAdornment>
                              }
                             
                           
                            placeholder={lang === 'en' ? 'Phone' : 'الهاتف'}
                            type={'text'}
                            sx={{
                              p: '15px 0px',
                              borderRadius: props.borderRadius ? '50px' : '0',
                              width: props.Buttons
                                ? { xs: '100%', md: '90%' }
                                : '100%',
                              mt: '20px',
                              border:
                                formik.values.email !== '' ||
                                formik.values.password !== ''
                                  ? '2px solid gray'
                                  : `2px solid ${
                                      props.borderInputColor
                                        ? props.borderInputColor
                                        : `2px solid black`
                                    }`,
                              '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                opacity: 0,
                              },
                              '& input::placeholder': {
                                color: props.placeholderColor
                                  ? props.placeholderColor
                                  : 'gray',
                              },
                            }}
                            name="phone"
                            value={formik.values.phone}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            onClick={() => {
                              formik.setFieldValue('registrationType', 'phone')
                            }}
                          />
                          {formik.errors.phone && formik.touched.phone && (
                            <Typography
                              fontWeight={'bold'}
                              fontSize={13}
                              color="red"
                              sx={{}}
                            >
                              {formik.errors.phone}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <ButtonBase
                    type="submit"
                    sx={{
                      color: '#fff',
                      bgcolor: props.backgroundBtn
                        ? props.backgroundBtn
                        : 'gray',
                      mt: '20px',
                      fontSize: {
                        xl: '35px',
                        lg: '20px',

                        xs: '18px',
                      },

                      py: {
                        lg: '20px',
                        xs: '16px',
                      },
                      px: '35px',
                      // fontWeight: 'bold',
                      borderRadius: props.borderRadius ? '50px' : '0',
                      width: props.Buttons ? { xs: '100%', md: '90%' } : '100%',
                    }}
                  >
                    {type === 'signUp'
                      ? lang === 'en'
                        ? 'Subscription'
                        : 'إشتراك'
                      : lang === 'en'
                      ? 'Login'
                      : 'اضغط للدخول'}
                  </ButtonBase>
                </Box>
              </Stack>
            </form>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            background:props.backgroundSvg?`url(${props.backgroundSvg})`: props.backgroundImag
              ? `url(${props.backgroundImag})`
              : props.backgroundColor,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            // i need to rotate background image

            position: 'relative',
            minHeight: {
              md: '100vh',
              xs: '380px',
            },
            width: '100%',
          }}
        >
          {props.logo && (
            <Box
              sx={{
                width: { xs: '250px', md: '300px' },
                height: { xs: '200px', md: '200px' },
                position: 'absolute',
                top: props.backgroundImag 
                  ? { xs: props.Buttons?"80%":'100%', md: props.center ? '50%' : '15%' }
                  : '50%',
                left: props.backgroundImag
                  ? !props?.Buttons
                    ? { xs: '50%', md: lang === 'en' ? '0%' : '100%' }
                    : { xs: '50%', md: lang === 'en' ? '-50%' : '150%' }
                  : '50%',
                transform: 'translate(-50%,-50%)',
              
                
              }}
            >
              <img
                src={props.logo}
                alt="logo"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
          )}

          {props.Buttons && (
            <Stack
              direction={{ xs: 'row', md: 'column' }}
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: props.backgroundImag ? { xs: '110%', md: '50%' } : '50%',

                left: { xs: '50%', md: lang === 'en' ? '0%' : '100%' },
                transform: 'translate(-50%,-50%)',
                gap: { xs: '20px', lg: '50px' },
                width: { xs: '90%', md: '30%' },
                mt: { xs: 5, md: '0' },
                zIndex:100
              }}
            >
              <Box
                sx={{
                  // px: 4,
                  py: 1.5,
                  minWidth: {xs:"150px",sm:'200px'},
                  borderRadius: props.borderRadius ? '50px' : '0px',
                  border: `2px solid ${
                    props.color ? props.color : `2px solid black`
                  }`,
                  backgroundColor:
                    type === 'signUp' ? props.color : 'transparent',

                  cursor: 'pointer',
                }}
                onClick={() => handelTypeAuth('signUp')}
              >
                <Typography
                  variant="h4"
                  textAlign="center"
                  sx={{
                    fontSize: {
                      xl: '20px',

                      sm: '15px',
                      xs: '18px',
                    },
                    fontWeight: 'bolder',
                    color:
                      type === 'signUp'
                        ? '#fff'
                        : props.color
                        ? props.color
                        : 'gray',
                  }}
                >
                  {lang === 'en' ? 'Create account' : 'إنشاء حساب'}
                </Typography>
              </Box>
              <Box
                sx={{
                  // px: 4,
                  py: 1.5,
                  minWidth: {xs:"150px",sm:'200px'},
                  borderRadius: props.borderRadius ? '50px' : '0px',
                  border: `2px solid ${
                    props.color ? props.color : `2px solid black`
                  }`,
                  backgroundColor:
                    type === 'login' ? props.color : 'transparent',
                  cursor: 'pointer',
                }}
                onClick={() => handelTypeAuth('login')}
              >
                <Typography
                  variant="h4"
                  textAlign="center"
                  sx={{
                    fontSize: {
                      xl: '20px',
                      lg: '18px',
                      sm: '20px',
                      xs: '18px',
                    },
                    fontWeight: 'bolder',
                    color:
                      type === 'login'
                        ? '#fff'
                        : props.color
                        ? props.color
                        : 'gray',
                  }}
                >
                  {lang === 'en' ? 'Login' : ' الدخول'}
                </Typography>
              </Box>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default AuthenticationUser
