import { Container } from '@mui/material'
import { useGetAllPrivcayQuery } from '../src/APIs/privacyApi'
// import { MultipleImages } from './MultipleImages'
// import { NoImage } from './NoImage'
import { SingleImage } from './SingleImage'
import { Types } from './PrivacyTypes'
export const Privacy = (props: Types) => {
  const { data } = useGetAllPrivcayQuery(undefined)
  console.log({ data })

  return (
    <Container sx={{ my: 5 }}>
      {/* {type.multipleImages && (
        <MultipleImages data={data?.data[0]} imgs={type.images} />
      )}
      {/* {type.noImage && <NoImage data={data?.data[0]} color={type.color} />} */}
      {props.singleImage ? (
        <SingleImage data={data?.data[0]} color={props.color} />
      ) : undefined}
    </Container>
  )
}
