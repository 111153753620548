import React from 'react'
import { ContactTypesGeneral, ContactUsProps } from '../contactType'
import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ContactForm from './ContactForm'

const ContactUs = (props: ContactUsProps) => {
  console.log("props in contact",props)

  const [, { language: lang }] = useTranslation()
  return (
    <Box
      sx={{
        direction: lang === 'en' ? 'ltr' : 'rtl',
        bgcolor: props.colors?.backgroundColor
          ? props.colors?.backgroundColor
          : 'gray',
        my: 5,
        py: {xs:4,sm:0},
      }}
    >
      <Grid container>
        {/* title */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: props.colors?.color ? props.colors?.color : 'white',
              
              fontSize: {
                xs: '40px',
                lg: '45px',
              },
              fontWeight: 'bold',
            }}
          >
            {lang === 'en' ? 'Contact Us' : 'تواصل معنا'}
          </Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <ContactForm colors={props.colors} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactUs
