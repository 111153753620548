import React, { useRef } from 'react'
import { Box, Typography,Stack } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import data from '../data.json'
import 'swiper/css'
import 'swiper/css/navigation'

import { Pagination, Navigation } from 'swiper/modules'


import { Card } from '../../Cards/Card'
import { MostSellerCollectionProps } from '../Most Seller/MostSellerCollection'
import { useGetMostNewiestProductsQuery, useGetMostSellingProductsQuery } from '../../src/APIs/ProductApis'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/joy'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
const TrendingScroll = (props:MostSellerCollectionProps) => {
  const { data:MostSeller, isSuccess:MostSuccess,isError:MostError,error:MostErrMsg } = useGetMostSellingProductsQuery(undefined)
  const { data:Newest, isSuccess,isLoading,error,isError } = useGetMostNewiestProductsQuery(undefined)
  const [_, { language: lang }] = useTranslation()
  const getData = props.title==="NewestScroll"?Newest?.data:MostSeller?.data
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null)
  console.log(error);
  
  return (
    <Box my={5} py={3}>
       {isError&&MostError && (
        <Box
        sx={{
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          minHeight:"80vh"
        }}
        >

        <Typography
          fontSize={'1.5rem'}
          my={10}
          textAlign={'center'}
          color="error"
        >
         {isError?(error as any)?.data&& (error as any)?.data[`error_${lang}`]:(MostErrMsg as any)?.data&& (MostErrMsg as any)?.data[`error_${lang}`]}
        </Typography>
        </Box>
      )}
      {(isSuccess&&MostSuccess ) && (!isError&&!MostError) &&getData?.length > 0&&(
        <>
      <Box textAlign={'center'}>
      <Typography
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.5rem', lg: '4rem' },
            fontWeight: 'bold',
            color: props.color ? props.color : "gray",
          }}
        >
          {props.title === 'NewestScroll'
            ? lang === 'en'
              ? 'Newest'
              : 'الاحدث'
            : lang === 'en'
            ? 'Most Seller'
            : 'الاكثر مبيعا'}
        </Typography>
      </Box>

      <Box width={{ xs: 1, md: 0.9,xl:0.7 }} mx={'auto'} mt={5} p={3} position={"relative"} >
        <Swiper
          slidesPerView={3}
          centeredSlides={true}
          spaceBetween={30}
          navigation={{
            prevEl: navigationPrevRef?.current,
            nextEl: navigationNextRef?.current,
          }}

          loop={true}
          modules={[ Navigation]}
          className="mySwiper"
          onBeforeInit={(swiper) => {
            if (
              swiper.params.navigation &&
              typeof swiper.params.navigation !== "boolean"
            ) {
              swiper.params.navigation.prevEl = navigationPrevRef?.current;
              swiper.params.navigation.nextEl = navigationNextRef?.current;
            }
          }}
          breakpoints={{
            320: {
              slidesPerView:1,
            },
            450: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 150,
            },

          }} 
        >
        <Stack direction={"row"} position={"absolute"} sx={{
          top:"40%",
          left:0,
          zIndex:1,
         justifyContent:"space-between",
         alignItems:"center",
         width:"100%",
        }}>
          <Button ref={navigationNextRef} onClick={()=>{navigationNextRef}}   sx={{
            background:"none !important",
          }}>
            <ArrowBackIosIcon sx={{
              fontSize:"50px",
              color:props.color ? props.color : "gray",
            }}/>
          </Button>
          <Button ref={navigationPrevRef} onClick={()=>{navigationPrevRef}}  sx={{
            background:"none !important",
          }}>
            <ArrowForwardIosIcon  sx={{
              fontSize:"50px",
              color:props.color ? props.color : "gray",
            }}/>
          </Button>
          
        </Stack>
          {getData?.map((item:any, index:number) => (
            <SwiperSlide key={index} style={{padding:1}}>
                 <Card
                data={item}
                colors={props.card.colors}
                border={props.card.border}
                borderRadius={props.card.borderRadius}
                wide={props.card.wide}
                width={props.card.width}
                height={props.card.height}
                title={props.card.title}
                desc={props.card.desc}
                button={props.card.button}
                favorite={props.card.favorite}
                hoverWithFav={props.card.hoverWithFav}
                hover={props.card.hover}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
        </>
      )}
    </Box>
  )
}

export default TrendingScroll
