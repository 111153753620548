import { useEffect, useState } from 'react'
import { useLazyGetAllProductsQuery } from '../src/APIs/ProductApis'
export const  useFetchProductsByCategoryIdAndSubId = (
  categoryId: string | undefined,
  subId: string | undefined
) => {
  const [products, setProducts] = useState([])
  const [getAllProducts, { data, isLoading }] = useLazyGetAllProductsQuery()
  useEffect(() => {
    if (categoryId && subId) {
      getAllProducts(`category=${categoryId}&subCategory=${subId}&limit=1000`)
        .unwrap()
        .then((res) => {
          setProducts(res.data)
        })
        .catch(() => {
          setProducts([])
        })
    }
  }, [data, subId, categoryId])
  return { categoryAndSubProducts: products, isLoading }
}
export const useFetchProductsByCategoryId = (
  categoryId: string | undefined
) => {
  const [products, setProducts] = useState([])
  const [getAllProducts, { data, isLoading }] = useLazyGetAllProductsQuery()
  useEffect(() => {
    if (categoryId) {
      getAllProducts(`category=${categoryId}&limit=1000`)
        .unwrap()
        .then((res) => {
          setProducts(res.data)
        })
        .catch(() => {
          setProducts([])
        })
    }
  }, [data, , categoryId])
  return { categoryProducts: products, isLoading }
}
