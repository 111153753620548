import SearchIcon from '@mui/icons-material/Search'
import { Box, Container, InputBase, Stack, Typography } from '@mui/material'
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card } from '../Cards/Card'
import {
  useGetAllProductsByCategoryQuery,
  useGetAllProductsQuery,
} from '../src/APIs/ProductApis'
import { useGetAllSavedProductsQuery } from '../src/APIs/SavedProductApi'
import { ParentDepPageTypes } from './ParentDepPageTypes'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'

// eslint-disable-next-line import/no-unresolved
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import AllProductsGrid from './AllProductsGrid'
import { useFetchProductsByCategoryIdAndSubId } from './useFetchFilteredProducts'
// import { Filter2 } from '../Filter/Filter2/Filter2'
// import { Filter3 } from '../Filter/Filter3/Filter3'
// import { Filter1 } from '../Filter/Filter1/Filter1'

export const ParentDepartmentPage = (props: ParentDepPageTypes) => {
  const { categoryId, subId } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const nav = useNavigate()
  const [, { language: lng }] = useTranslation()
  const { data: allProducts, isLoading: allProductsLoading } =
    useGetAllProductsByCategoryQuery('undefined')
  const { data: categoryProducts, isLoading: categoryProductsLoading } =
    useGetAllProductsQuery(`limit=10000&category=${categoryId}`)
  const { data: favPros, isLoading: favLoading } =
    useGetAllSavedProductsQuery(undefined)

  const [query, setQuery] = useState('')
  const { categoryAndSubProducts, isLoading: categAndSubProductsLoading } =
    useFetchProductsByCategoryIdAndSubId(categoryId, subId)
  // Function to handle search query changes
  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
    if (event.target.value.length === 0) {
      setSearchResults([])
    }
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  console.log(data)
  useEffect(() => {
    if (props.type === 'allProducts') {
      setData(allProducts?.data)
      setLoading(allProductsLoading)
      setSearchResults([])
    } else if (props.type === 'category') {
      setData(categoryProducts?.data)
      setLoading(categoryProductsLoading)
      setSearchResults([])
    } else if (props.type === 'favorite') {
      setData(favPros?.data?.favourite)
      setLoading(favLoading)
      setSearchResults([])
    } else if (props.type === 'subCategory') {
      setData(categoryAndSubProducts)
      setLoading(categAndSubProductsLoading)
    }
  }, [
    allProducts,
    categoryProducts,
    props.type,
    allProductsLoading,
    categoryProductsLoading,
    favPros,
    favLoading,
    categoryAndSubProducts,
    categAndSubProductsLoading,
  ])

  // Function to handle search submission
  const handleSearch = () => {
    const dataSearch = []
    props.type === 'allProducts' &&
      data.map((item) => {
        dataSearch.push(...item?.products)
      })

    const filteredResults =
      props.type !== 'allProducts'
        ? data?.filter(
            (item: any) =>
              item?.title_en?.toLowerCase().includes(query.toLowerCase()) ||
              item?.title_ar?.includes(query)
          )
        : dataSearch?.filter(
            (item: any) =>
              item?.title_en?.toLowerCase().includes(query.toLowerCase()) ||
              item?.title_ar?.includes(query)
          )
    setSearchResults(filteredResults)
  }
  return (
    <Container
      sx={{
        // mx: { xs: 1, md: 5 },
        my: 5,
        // px: { xs: 1, md: 5 },
        minHeight: '70vh',
        direction: lng === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          justifyContent: props.filter1
            ? 'space-between'
            : props.type === 'favorite'
            ? 'space-around'
            : 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {' '}
        {props.type === 'favorite' &&
          `${data?.length ? data?.length : 0} | ${
            lng === 'en' ? 'Favorite' : 'المفضله'
          }`}
        {/* {props.filter1  && (
          <Box>
            <Filter1 />
          </Box>
        )} */}
        {/* {props.filter2 && (
          <Box>
            <Filter2 />
          </Box>
        )}
        {props.filter3 && (
          <Box>
            <Filter3 />
          </Box>
        )} */}
        <Stack
          direction={'row'}
          sx={{
            border: '1px solid lightgrey',
            borderRadius: props.type === 'favorite' ? 0 : 2,
            p: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: props.type === 'favorite' ? { xs: '50%', md: '25%' } : '90%',
          }}
        >
          <InputBase
            placeholder={lng === 'en' ? 'Search' : 'البحث'}
            sx={{ width: '100%' }}
            value={query}
            onKeyDown={handleKeyDown}
            onChange={handleQueryChange}
          />
          <SearchIcon
            sx={{ color: 'dimgrey', cursor: 'pointer' }}
            onClick={handleSearch}
          />
        </Stack>
      </Stack>
      <Stack
        direction={'column'}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {!loading && (data?.length < 1 || data?.length === undefined) && (
          <Typography color={'error'} sx={{ m: 5, fontSize: '2rem' }}>
            {lng === 'en' ? 'No products found' : 'لا يوجد منتجات'}
          </Typography>
        )}
        {!loading ? (
          props.type === 'allProducts' ? (
            data?.map(
              (item: any) =>
                item?.products?.length > 0 && (
                  // <Container key={item.category.name_en}>
                  <>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        mt: 5,
                        fontSize: '1.5rem',
                        cursor: 'pointer',
                        width: '100%',
                        textAlign: 'start',
                      }}
                      onClick={() => nav(`/departments/${item.category._id}`)}
                    >
                      {item.category[`name_${lng === 'en' ? 'en' : 'ar'}`]}
                    </Typography>
                    {props?.grid ? (
                      searchResults.length !== 0 ? (
                        <AllProductsGrid
                          cards={searchResults?.map((pro: any) => (
                            <Card
                              key={pro._id}
                              data={pro}
                              colors={props.card.colors}
                              border={props.card.border}
                              borderRadius={props.card.borderRadius}
                              wide={props.card.wide}
                              // width={props.card.width}
                              height={350 || props.card.height}
                              title={props.card.title}
                              desc={props.card.desc}
                              button={props.card.button}
                              favorite={props.card.favorite}
                              hoverWithFav={props.card.hoverWithFav}
                              hover={props.card.hover}
                            />
                          ))}
                        />
                      ) : (
                        <AllProductsGrid
                          cards={item?.products?.map((pro: any) => (
                            <Card
                              key={pro._id}
                              data={pro}
                              colors={props.card.colors}
                              border={props.card.border}
                              borderRadius={props.card.borderRadius}
                              wide={props.card.wide}
                              // width={props.card.width}
                              height={350 || props.card.height}
                              title={props.card.title}
                              desc={props.card.desc}
                              button={props.card.button}
                              favorite={props.card.favorite}
                              hoverWithFav={props.card.hoverWithFav}
                              hover={props.card.hover}
                            />
                          ))}
                        />
                      )
                    ) : (
                      <Box
                        component={Swiper}
                        slidesPerView={4}
                        spaceBetween={10}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          py: 2,
                          px: 1,
                          width: '100%',
                        }}
                        breakpoints={{
                          '@0.25': {
                            slidesPerView: 2,
                            spaceBetween: 10,
                          },
                          '@0.50': {
                            slidesPerView: 2,
                            spaceBetween: 40,
                          },
                          '@0.75': {
                            slidesPerView: 3.5,
                            spaceBetween: 40,
                          },
                          '@1.00': {
                            slidesPerView: 4,
                            spaceBetween: 40,
                          },
                        }}
                      >
                        {item.products.map((pro: any) => (
                          <SwiperSlide key={pro._id}>
                            <Card
                              data={pro}
                              colors={props.card.colors}
                              border={props.card.border}
                              borderRadius={props.card.borderRadius}
                              wide={props.card.wide}
                              width={props.card.width}
                              height={props.card.height}
                              title={props.card.title}
                              desc={props.card.desc}
                              button={props.card.button}
                              favorite={props.card.favorite}
                              hoverWithFav={props.card.hoverWithFav}
                              hover={props.card.hover}
                            />
                          </SwiperSlide>
                        ))}
                      </Box>
                    )}
                  </>
                  // </Container>
                )
            )
          ) : (
            <Stack
              direction={'column'}
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                gap: 5,
                flexWrap: 'wrap',
                width: '100%',
                my: 5,
              }}
            >
              <Stack
                direction={'row'}
                sx={{
                  justifyContent: props.search ? 'space-around' : 'start',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {/* {props.search && data?.length > 0 && (
                  <Stack
                    direction={'row'}
                    sx={{
                      border: '1px solid lightgrey',
                      borderRadius: props.type === 'favorite' ? 0 : 2,
                      p: 1,
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width:
                        props.type === 'favorite'
                          ? { xs: '50%', md: '25%' }
                          : '90%',
                    }}
                  >
                    <InputBase
                      placeholder={lng === 'en' ? 'Search' : 'البحث'}
                      sx={{ width: '100%' }}
                      value={query}
                      onKeyDown={handleKeyDown}
                      onChange={handleQueryChange}
                    />
                    <SearchIcon
                      sx={{ color: 'dimgrey', cursor: 'pointer' }}
                      onClick={handleSearch}
                    />
                  </Stack>
                )} */}
                <div />
              </Stack>
              <AllProductsGrid
                cards={
                  searchResults?.length > 0
                    ? searchResults.map((pro: any, index) => (
                        <Card
                          key={pro._id}
                          data={pro}
                          colors={props.card.colors}
                          border={props.card.border}
                          borderRadius={props.card.borderRadius}
                          wide={props.card.wide}
                          // width={props.card.width}
                          height={350 || props.card.height}
                          title={props.card.title}
                          desc={props.card.desc}
                          button={props.card.button}
                          favorite={props.card.favorite}
                          hoverWithFav={props.card.hoverWithFav}
                          hover={props.card.hover}
                        />
                      ))
                    : data?.map((pro: any, index) => (
                        <Card
                          key={pro._id}
                          data={pro}
                          colors={props.card.colors}
                          border={props.card.border}
                          borderRadius={props.card.borderRadius}
                          wide={props.card.wide}
                          // width={props.card.width}
                          height={350 || props.card.height}
                          title={props.card.title}
                          desc={props.card.desc}
                          button={props.card.button}
                          favorite={props.card.favorite}
                          hoverWithFav={props.card.hoverWithFav}
                          hover={props.card.hover}
                        />
                      ))
                }
              />
            </Stack>
          )
        ) : (
          <span className="loader"></span>
        )}
      </Stack>
    </Container>
  )
}
