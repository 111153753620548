import React from 'react'
import { Box, Button, Stack, Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
const NavCategoryMenu = (props) => {
  const [_, { language: lng }] = useTranslation()
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const theme = useTheme()
  const phoneScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        width: {
          lg: props.direction === 'vertical' ? 1 : 190,
          xs: props.blockStyle ? 1 : 0.9,
        },
        mx: {
          lg: props.direction === 'vertical' ? 'auto' : 0,
          xs: 'auto',
        },
        mb: {
          lg: '7px',
          xs: props.blockStyle ? 0 : '10px',
        },
        pr: {
          lg: 0,
          xs: lng === 'ar' ? '2px' : undefined,
        },
        pl: {
          lg: 0,
          xs: lng === 'en' ? '2px' : undefined,
        },
        borderLeft: {
          lg: 0,
          xs:
            lng === 'en'
              ? props.buttonStyle &&
                pathname.includes(`/departments/${props.item.id}`)
                ? `4px solid ${props.colors.activeDrawerColor}`
                : `1px solid transparent`
              : `1px solid transparent`,
        },
        borderRight: {
          lg: 0,
          xs:
            lng === 'ar'
              ? props.buttonStyle &&
                pathname.includes(`/departments/${props.item.id}`)
                ? `4px solid ${props.colors.activeDrawerColor}`
                : `1px solid transparent`
              : `1px solid transparent`,
        },
      }}
    >
      <Button
        title={
          props.item[`title_${lng === 'en' ? 'en' : 'ar'}`].length > 11
            ? props.item[`title_${lng === 'en' ? 'en' : 'ar'}`]
            : null
        }
        sx={{
          mx: {
            lg: 0,
            xs: 'auto',
          },

          width: 1,
          display: 'flex',
          justifyContent: {
            lg: 'center',
            xs: 'space-between',
          },
          border:
            props.blockStyle || props.buttonStyle
              ? `1px solid ${props.colors.borderColor}`
              : 'transparent',

          borderBottom: {
            lg: !props.buttonStyle
              ? pathname.includes(`/departments/${props.item.id}`)
                ? `2px solid ${props.colors.activeBorderColor}`
                : `1px solid ${
                    !props.blockStyle ? 'transparent' : props.colors.borderColor
                  }`
              : `1px solid ${props.colors.borderColor}`,
            xs: `1px solid ${
              props.buttonStyle ? props.colors.borderColor : 'transparent'
            }`,
          },

          color: {
            lg: pathname.includes(`/departments/${props.item.id}`)
              ? props.blockStyle
                ? props.colors.linkTextColor
                : props.colors.activeLinkColor
              : props.colors.linkTextColor,
            xs: pathname.includes(`/departments/${props.item.id}`)
              ? props.colors.activeDrawerColor
              : props.colors.drawerColor,
          },
          bgcolor: {
            lg: `${
              !props.buttonStyle
                ? props.colors.linkTextBgColor
                : pathname.includes(`/departments/${props.item.id}`)
                ? props.colors.activeLinkBgColor
                : props.colors.linkTextBgColor
            } !important`,
            xs: `${props.colors.linkTextBgColor} !important`,
          },
          cursor: 'pointer',
          fontSize: {
            xs: '1rem',
            md: '1rem',
            lg: '1.2rem',
          },
          textTransform: 'capitalize',
          borderRadius: props.buttonStyle
            ? props.buttonStyle === 'radius'
              ? 3
              : 6
            : 0,
          p: props.blockStyle
            ? '.7rem 1rem'
            : props.buttonStyle
            ? '8px 10px'
            : 0,
          gap: '7px',
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: {
              lg: 'center',
              xs:
                !props.blockStyle && !props.buttonStyle
                  ? 'flex-start'
                  : 'center',
            },
            width: {
              lg: 'auto',
              xs: 1,
            },
          }}
        >
          {phoneScreen ? (
            <span>
              {props.item[`title_${lng === 'en' ? 'en' : 'ar'}`].length > 20
                ? props.item[`title_${lng === 'en' ? 'en' : 'ar'}`].slice(
                    0,
                    20
                  ) + '...'
                : props.item[`title_${lng === 'en' ? 'en' : 'ar'}`]}
            </span>
          ) : (
            <span>
              {props.item[`title_${lng === 'en' ? 'en' : 'ar'}`].length > 12
                ? props.item[`title_${lng === 'en' ? 'en' : 'ar'}`].slice(
                    0,
                    12
                  ) + '...'
                : props.item[`title_${lng === 'en' ? 'en' : 'ar'}`]}
            </span>
          )}

          <ArrowDropDownIcon
            sx={{
              transition: 'all 0.4s',
              color: {
                lg: pathname.includes(`/departments/${props.item.id}`)
                  ? props.blockStyle
                    ? props.colors.linkTextColor
                    : props.colors.activeLinkColor
                  : props.colors.linkTextColor,
                xs: pathname.includes(`/departments/${props.item.id}`)
                  ? props.colors.activeDrawerColor
                  : props.colors.drawerColor,
              },
              transform: open ? 'rotate(180deg)' : 'rotatex(0)',
            }}
          />
        </Stack>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '.MuiList-root': {
            width: {
              lg: 230,
              xs: 230,
            },
            '.MuiButtonBase-root': {
              justifyContent: 'center',
            },
          },
          '.MuiPaper-root': {
            bgcolor: props.colors.menuBgColor,
          },
        }}
      >
        {props.item.subs.map((sub: any) => (
          <MenuItem
            key={sub.id}
            sx={{
              bgcolor: `${
                pathname === `/departments/${props.item.id}/${sub.id}`
                  ? props.colors.activeMenuItemBgColor
                  : props.colors.menuItemBgColor
              } !important`,
              color:
                pathname === `/departments/${props.item.id}/${sub.id}`
                  ? props.colors.activeMenuItemColor
                  : props.colors.menuItemColor,
              mb: '5px',
            }}
            onClick={() => {
              handleClose()
              navigate(`/departments/${props.item.id}/${sub.id}`)
              if (props.handleDrawerToggle) {
                props.handleDrawerToggle()
              }
            }}
          >
            {sub[`title_${lng}`]}
          </MenuItem>
        ))}
        {props.item.subs.length > 1 && (
          <MenuItem
            sx={{
              bgcolor: `${
                pathname === `/departments/${props.item.id}`
                  ? props.colors.activeMenuItemBgColor
                  : props.colors.menuItemBgColor
              } !important`,
              color:
                pathname === `/departments/${props.item.id}`
                  ? props.colors.activeMenuItemColor
                  : props.colors.menuItemColor,
            }}
            onClick={() => {
              handleClose()
              navigate(`/departments/${props.item.id}`)
              if (props.handleDrawerToggle) {
                props.handleDrawerToggle()
              }
            }}
          >
            {lng === 'en' ? 'All sub categories' : 'جميع الأقسام الفرعية'}
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}

export default NavCategoryMenu
