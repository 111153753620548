import {
  Avatar,
  Badge,
  Box,
  Button,
  CardMedia,
  Grid,
  InputBase,
  Paper,
  Stack,
  // TextField,
  Typography,
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
// import { Container } from '@mui/system'
import { useFormik } from 'formik'
import { inputs } from './ProfileData'
// import { formData } from './Profile.form'
import EditIcon from '@mui/icons-material/Edit'
import UploadIcon from '@mui/icons-material/Upload'
import { useTranslation } from 'react-i18next'
import { ProfileTypes } from './ProfileTypes'
import { useGetMeQuery, useLazyGetMeQuery } from '../src/APIs/UserApis'
import { useUpdateUserMutation } from '../src/APIs/UserApis'
import { ChangeEventHandler, useEffect, useRef, useState } from 'react'
// import moment from 'moment/moment'
import { toast } from 'react-toastify'

import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../src/APIs/userSlice'

import orderImg from './R.jpeg'
import userImg from './me.jpg'
import { ProfileFormik } from './ProfileFormik'
import { useGetUserOrdersQuery } from '../src/APIs/ordersApi'
import { useUploadImageMutation } from '../src/APIs/UploadsApi'
import { imageBaseUrl } from '../src/APIs/baseUrl'

const OrderCard = ({ items, tracking, id, shape }: any) => {
  console.log({ items, tracking, id })
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography textAlign={'center'}>
          {language === 'en' ? 'Order Number' : 'رقم الطلب'} {id}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          direction="row"
          // justifyContent={'space-between'}
          gap={2}
          p={2}
        >
          {items.map(
            (
              { totalPrice, quantity, product, properties }: any,
              index: number
            ) => (
              <Grid
                item
                xs={12}
                md={shape === 'horizontal' ? 12 : 5}
                key={index}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent={'space-between'}
                  spacing={2}
                  sx={{
                    color: 'inherit',
                  }}
                >
                  <Grid item xs={12} md={5}>
                    <CardMedia
                      component="img"
                      height="240"
                      width={'100%'}
                      image={`${imageBaseUrl}${product.images[0]}`}
                      alt={product.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Typography variant="h6">
                      {language === 'en' ? 'Name' : 'الاسم'} :{' '}
                      {language === 'en' ? product.title_en : product.title_ar}
                    </Typography>

                    <Typography variant="h6">
                      {language === 'en' ? 'Price' : 'السعر'} : {totalPrice}{' '}
                      {language === 'en' ? 'Rial' : 'ريال'}
                    </Typography>

                    <Typography variant="h6">
                      {language === 'en' ? 'Quantity' : 'الكمية'} : {quantity}
                    </Typography>
                    {properties?.map((property: any) => (
                      <Typography variant="h6">
                        {language === 'en' ? property.key_en : property.key_ar}{' '}
                        :{' '}
                        {language === 'en'
                          ? property.value_en
                          : property.value_ar}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )
          )}
          {tracking.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {tracking.map(({ orderNumberTracking, path }: any) => (
                  <Grid
                    item
                    xs={12}
                    md={shape === 'horizontal' ? 12 : 6}
                    key={orderNumberTracking}
                  >
                    <Typography
                      component={Paper}
                      elevation={3}
                      p={2}
                      variant="h6"
                      textAlign={'center'}
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        window.open(path, '_blank')
                      }}
                    >
                      {language === 'en' ? 'Tracking Number' : 'رقم التتبع'}{' '}
                      {orderNumberTracking}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

const getOrderData = (orders: any) => {
  // let cashItems = (
  //   orders?.map((item: any) => item.cashItems.items) || []
  // ).flat()
  // const onlineItems = (
  //   orders?.map((item: any) => item.onlineItems.items) || []
  // ).flat()
  // const tracking = orders?.map((item: any) => item.tracking).flat()
  // const ordersItems = [...cashItems, ...onlineItems]
  // return { ordersItems, tracking }
  const ordersItems = orders?.map((item: any) => ({
    _id: item._id,
    items: [...item.cashItems.items, ...item.onlineItems.items],
    tracking: item.tracking,
  }))

  return ordersItems
}

export const Profile = (props: ProfileTypes) => {
  const { data, isSuccess, error, isLoading, isError } =
    useGetMeQuery(undefined)
  const [getMe] = useLazyGetMeQuery()
  const { data: orders, isSuccess: success } = useGetUserOrdersQuery(undefined)
  const ordersItems = getOrderData(orders?.data || [])

  console.log(getOrderData(orders?.data || []))

  const [image, setImage] = useState()
  const [uploadImage, { isLoading: uploadingLoading }] =
    useUploadImageMutation()
  const [, { language }] = useTranslation()
  const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation()
  const [userId, setUserId] = useState()
  const dispatch = useDispatch()
  const { profileData, profileValidation } = ProfileFormik()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [uploadedImage, setUploadedImage] = useState<any>()
  const handelUploadImage = () => {
    inputFileRef.current?.click()
  }
  const handelChangeImage = (e: any) => {
    const file = e.target.files[0]
    setUploadedImage(file)
  }
  const handleUpdate = (values: any) => {
    updateUser(values)
      .unwrap()
      .then((res) => {
        toast.success(language === 'en' ? res?.success_en : res?.success_ar)
        dispatch(setCurrentUser(res?.data))
      })
      .catch((err) => {
        toast.error(language === 'en' ? err?.error_en : err?.error_ar)
      })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      password: '',
      image: '',
    },
    validationSchema: profileValidation,
    onSubmit: (values) => {
      if (uploadedImage) {
        const data = new FormData()
        data.append('image', uploadedImage)
        const dotIndex = uploadedImage.name.lastIndexOf('.')
        const type = uploadedImage.name.slice(
          dotIndex + 1,
          uploadedImage.name.length
        )
        uploadImage({ file: data, type })
          .unwrap()
          .then((res) => {
            handleUpdate({ ...values, image: res.image })
          })
          .catch((err) => {})
      } else {
        handleUpdate(values)
      }
    },
  })
  useEffect(() => {
    getMe(undefined).then(({ data }) => {
      formik.setValues({
        name: data?.data?.name,
        email: data?.data?.email,
        phone: data?.data?.phone,
        password: '',
        image: data?.data?.image,
      })
    })
  }, [data?.data])

  return (
    <Stack
      direction={
        props.shapeProfile === 'horizontal'
          ? { xs: 'column', md: 'row' }
          : 'column'
      }
      justifyContent={'center'}
      gap={{ xs: 3, lg: 6 }}
      sx={{
        direction: language === 'en' ? 'ltr' : 'rtl',
        my: 10,
        width: { xs: '90%', md: '95%', lg: '85%', xl: '80%' },
        mx: 'auto',
        minHeight: '100vh',
      }}
    >
      {/* FORM */}
      {isLoading && <span className="loader"></span>}
      {isError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
          }}
        >
          <Typography
            fontSize={'1.5rem'}
            my={10}
            textAlign={'center'}
            color="error"
          >
            {(error as any)?.data && (error as any)?.data[`error_${language}`]}
          </Typography>
        </Box>
      )}
      {!isLoading && isSuccess && (
        <>
          <Box sx={{ flex: 1 }}>
            <Paper
              component="form"
              onSubmit={formik.handleSubmit}
              elevation={3}
              sx={{
                width: '100%',
                py: 4,
                borderRadius: 7,
                bgcolor: props.colors.primaryBgColor,
              }}
            >
              <Badge
                sx={{
                  display: 'block',
                  width: { xs: '160px', sm: '180px', md: '150px', lg: '260px' },
                  height: {
                    xs: '160px',
                    sm: '180px',
                    md: '150px',
                    lg: '260px',
                  },
                  mx: 'auto',
                  mb: 5,
                }}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <Box onClick={handelUploadImage}>
                    <Avatar
                      sx={{
                        bgcolor: `${props.colors.titleColor} !important`,
                        transform: `translate(${
                          language === 'ar' ? '-500%' : '50%'
                        }, 50%)`,
                      }}
                    >
                      <EditIcon sx={{ cursor: 'pointer' }} />
                    </Avatar>
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputFileRef}
                      style={{
                        visibility: 'hidden',
                      }}
                      onChange={handelChangeImage}
                    />
                  </Box>
                }
              >
                <Avatar
                  alt="Travis Howard"
                  src={
                    uploadedImage
                      ? URL.createObjectURL(uploadedImage)
                      : `${imageBaseUrl}/${formik.values.image}`
                  }
                  sx={{
                    border: `1px solid ${props.colors.borderColor}`,
                    width: 1,
                    height: '100%',
                    '& .MuiAvatar-img': {
                      objectFit: 'fill',
                    },
                  }}
                />
              </Badge>
              <Grid
                container
                spacing={{ xs: 2, md: 5 }}
                rowSpacing={3}
                justifyContent="center"
              >
                {inputs.map((inpt, index) => (
                  <Grid
                    key={index}
                    item
                    md={props.shapeProfile === 'horizontal' ? 10 : 5}
                    xs={props.shapeProfile === 'horizontal' ? 10 : 10}
                  >
                    <Box
                      sx={{
                        mt: '20px',
                      }}
                    >
                      <Typography
                        component="label"
                        sx={{
                          fontWeight: 'bold ',
                          color: props.colors.labelColor,
                          fontSize: {
                            xs: '.85rem',
                            md: '.95rem',
                            lg: '1.1rem',
                          },
                        }}
                      >
                        {language === 'en' ? inpt.label_en : inpt.label_ar}
                      </Typography>

                      <InputBase
                        type={inpt.type === 'password' ? 'password' : 'text'}
                        name={inpt.name}
                        value={formik.values[inpt.name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={
                          language === 'en' ? inpt.label_en : inpt.label_ar
                        }
                        sx={{
                          mt: '15px',
                          display: 'block',
                          backgroundColor: '#fff',
                          p: { xs: '8px', md: '10px' },
                          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
                          borderRadius: '50px',
                          border: '1px solid gray',
                          fontSize: { xs: '.95rem', lg: '1.1rem' },
                        }}
                      />
                      {formik.touched[inpt.name] && formik.errors[inpt.name] ? (
                        <Typography color="red">
                          {formik.errors[inpt.name]}
                          {/* {inpt.name} */}
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Stack direction="row" justifyContent={'center'} gap={3} mt={5}>
                <Button
                  variant="contained"
                  disabled={loadingUpdate || uploadingLoading}
                  type="submit"
                  sx={{
                    bgcolor: `${props.colors.buttonBgColor} !important`,
                    color: `${props.colors.buttonColor} !important`,
                    p: {
                      xs: '.5rem 2rem',
                      md: '.6rem 2.5rem',
                      lg: '.8rem 3rem',
                    },
                    fontSize: { xs: '1rem', lg: '1.1rem' },
                    textTransform: 'capitalize',
                    borderRadius: '50px',
                  }}
                >
                  {loadingUpdate || uploadingLoading
                    ? language === 'en'
                      ? 'Updating...'
                      : 'جاري التحديث'
                    : language === 'en'
                    ? 'Update'
                    : 'تحديث'}
                </Button>
              </Stack>
            </Paper>
          </Box>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection:
                props.shapeProfile === 'horizontal'
                  ? 'column'
                  : { xs: 'column', lg: 'row' },
              flex: 1,
              gap: 5,
              p: 4,
              borderRadius: 7,
              bgcolor: props.colors.secondaryBgColor,
            }}
          >
            {orders?.data.length !== 0 ? (
              <Box
                className="previousOrders"
                sx={{
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    mb: 2,
                    color: props.colors.titleColor,
                  }}
                >
                  {language === 'en' ? 'Orders' : 'الطلبات '}
                </Typography>
                <Grid
                  container
                  direction="row"
                  justifyContent={'space-between'}
                  width={'100%'}
                  // flexWrap={'wrap'}
                  // spacing={2}
                  gap={2}
                >
                  {ordersItems.map(
                    ({ items, tracking, _id }: any, index: number) => (
                      <Grid
                        component={Paper}
                        item
                        key={index}
                        xs={12}
                        width={'100%'}
                      >
                        <OrderCard
                          items={items}
                          tracking={tracking}
                          id={_id}
                          shape={props.shapeProfile}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    mb: 2,
                    color: 'red',
                  }}
                >
                  {language === 'en' ? 'No Orders' : 'لا يوجد طلبات'}
                </Typography>
              </Box>
            )}
          </Paper>
        </>
      )}
    </Stack>
  )
}
