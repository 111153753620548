import { Box, Stack, Typography } from '@mui/material'
const MAP_TYPE = {
  cash: {
    ar: 'عند الاستلام',
    en: 'cash',
  },
  online: {
    ar: 'الدفع اونلاين',
    en: 'online',
  },
} as any
function CustomPaymentType({ type, lang }: { type: any; lang: any }) {
  return (
    <Stack direction={'row'} gap={2}>
      <Typography variant="h6">
        {lang === 'ar' ? MAP_TYPE[type].ar : MAP_TYPE[type].en}
      </Typography>

      <Typography variant="h6">
      {" :"} {lang === 'en' ? 'Payment Type' : 'الدفع'} 
      </Typography>
    </Stack>
  )
}

export default CustomPaymentType
