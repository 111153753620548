import { Box, InputBase, Stack, Typography, Container } from '@mui/material'
import React from 'react'
import { ContactUsInput } from './ContactUsType'
import { FormLabel } from '@mui/joy'

const ContactTextInput = (props: ContactUsInput) => {
  return (
    <Box>
      <Container>
      
          <Box my={2}  >
            <FormLabel
              sx={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: props.color?.textColor ? props.color.textColor : 'black',
              }}
            >
              {props.placeholder}
            </FormLabel>
          </Box>
      
        <InputBase
          type="text"
          value={props.value}
          name={props.name}
          placeholder={props.background ? undefined : props.placeholder}
          sx={{
            bgcolor: 'transparent !important',
            width: '100%',
            p: '18px 20px',
           
            color: 'black',
            border:
              props.error && props.touched
                ? `2px solid red`
                : `2px solid ${props.color?.borderColor ? props.color?.borderColor : 'black'}`,
            borderRadius: "50px",
            '& input::placeholder': {
              fontSize: '20px',
              // Add any other placeholder styles here if needed
            },
          }}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
        />
        <Typography
          sx={{
            fontSize: '12px',
            color: 'red',
          }}
        >
          {props.error && props.touched ? props.error : undefined}
        </Typography>
      </Container>
    </Box>
  )
}

export default ContactTextInput
