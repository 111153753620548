import { Box, Container } from '@mui/material'
import { PrivacyTypes } from './PrivacyTypes'
import { imageBaseUrl } from '../src/App'

export const SingleImage = ({ data, color }: PrivacyTypes) => {
  return (
    <Container sx={{ my: 5 }}>
      <Box
        sx={{
          with: { xs: '95%', md: '90%' },
          height: 300,
          background: `url(${imageBaseUrl}${data?.image}) center center`,
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Box
        sx={{ lineHeight: 2, fontSize: 15, color }}
        dangerouslySetInnerHTML={{
          __html: data?.description_en,
        }}
      />
    </Container>
  )
}
