import {
  Box,
  Button,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useEffect, useState } from 'react'
import { TCartColors } from './CartTypes'
import {
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetAllCartsQuery,
} from '../src/APIs/cartApi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { imageBaseUrl } from '../src/App';

const cartPrice = (total: number, quantity: number, price:number)=>{
  const pricePlusTax = total/quantity;
  const tax = pricePlusTax-price;
  return tax;
}
export const Cart04 = ({ colors }: TCartColors) => {
  const [addToCart] = useAddToCartMutation()
  const { data, isSuccess, isError, isLoading } = useGetAllCartsQuery(undefined)

  const [, { language: lng }] = useTranslation()
  const [removeItem] = useDeleteFromCartMutation()
  const nav = useNavigate()
  const [fullCart, setFullCart] = useState<any>()

  useEffect(() => {
    isSuccess &&
      setFullCart([
        ...data?.data?.cashItems?.items,
        ...data?.data?.onlineItems?.items,
      ])
  }, [data?.data?.cashItems?.items, data?.data?.onlineItems.items, isSuccess])

  const updateQty = (method: string, id: string, qty: number) => {
    if (method === '+') {
      // setQty((prev) => prev + 1),
      addToCart({
        quantity: qty + 1,
        properties: [],
        id: id,
      })
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    } else {
      // setQty((prev) => prev - 1)
      addToCart({
        quantity: qty - 1,
        properties: [],
        id: id,
      })
        .unwrap()
        .then((res) => {
          toast.success(res[`success_${lng === 'en' ? 'en' : 'ar'}`])
        })
        .catch((e) =>
          toast.error(e.data[`error_${lng === 'en' ? 'en' : 'ar'}`])
        )
    }
  }

  return (
    <Container sx={{ overflowX: 'auto', my: 4 }}>
      {isLoading && <span className="loader"></span>}
      {isError && (
        <Typography
          fontSize={'1.5rem'}
          my={10}
          textAlign={'center'}
          color="error"
        >
          {lng === 'en' ? 'No items' : 'لا يوجد منتجات'}
        </Typography>
      )}
      {!isLoading && isSuccess && (
        <>
          <Table sx={{ minWidth: 650, my: 5 }}>
            <TableHead
              sx={{
                border: '1px solid lightgrey',
                bgcolor: '#FBFBFB',
              }}
            >
              <TableRow>
                <TableCell sx={{ color: colors.header }}>
                  {lng === 'en' ? 'Product' : 'المنتجات'}
                </TableCell>
                <TableCell sx={{ color: colors.header }}>
                  {lng === 'en' ? 'Price' : 'السعر'}
                </TableCell>
                <TableCell sx={{ color: colors.header }}>
                  {lng === 'en' ? 'Quantity' : 'الكمية'}
                </TableCell>
                <TableCell sx={{ color: colors.header }}>
                  {lng === 'en' ? 'Total Price' : 'إجمالي السعر'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: '1px solid lightgrey' }}>
              {isSuccess &&
                fullCart?.map((item: any) => (
                  <TableRow
                    sx={{ borderBottom: '1px solid black' }}
                    key={item?.product?.title_en}
                  >
                    <TableCell>
                      <Stack
                        direction={'row'}
                        sx={{ gap: 5, alignItems: 'center' }}
                      >
                        <CloseRoundedIcon
                          sx={{
                            color: colors.remove,
                            border: `1px solid ${colors.remove}`,
                            borderRadius: 50,
                            cursor: 'pointer',
                            fontSize: '1rem',
                          }}
                          onClick={() =>
                            removeItem(item.product.id)
                              .unwrap()
                              .then((res) => {
                                toast.success(
                                  res[`success_${lng === 'en' ? 'en' : 'ar'}`]
                                )
                              })
                              .catch((e) =>
                                toast.error(
                                  e.data[`error_${lng === 'en' ? 'en' : 'ar'}`]
                                )
                              )
                          }
                        />
                        <Box
                          sx={{
                            width: '75px',
                            height: '75px',
                            background: `url("${
                              imageBaseUrl + item.product.images[0]
                            }") center center`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                          }}
                        />
                        <Typography sx={{ color: colors.title }}>
                          {item.product[`title_${lng === 'en' ? 'en' : 'ar'}`]}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ color: colors.priceBefore }}>
                      <Typography>
                        <Box
                          sx={{
                            color: colors.priceAfter,
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                          }}
                        >
                          <Box component={'span'} sx={{ mx: 0.5 }}>
                            {lng === 'en' ? 'SAR' : 'ر.س'}
                          </Box>
                          {item.product.priceAfterDiscount > 0
                            ? (cartPrice(item.total, item.quantity, item.product.finalPrice) + item.product.priceAfterDiscount)
                            : (cartPrice(item.total, item.quantity, item.product.finalPrice) + item.product.priceBeforeDiscount)}
                        </Box>
                        {item.product.priceAfterDiscount > 0 && (
                          <Box
                            component={'span'}
                            sx={{
                              color: colors.priceBefore,
                              textDecoration: `line-through 1px solid `,
                              fontSize: item.product.priceAfterDiscount > 0
                                ? '0.7rem'
                                : '1.5rem',
                            }}
                          >
                            {`${(cartPrice(item.total, item.quantity, item.product.finalPrice) + item.product.priceBeforeDiscount)} ${
                              lng === 'en' ? 'SAR' : 'ر.س'
                            }`}
                          </Box>
                        )}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Stack
                        direction={'row'}
                        sx={{
                          alignItems: 'center',
                          p: 0,
                        }}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() =>
                            updateQty('-', item.product._id, item.quantity)
                          }
                          sx={{
                            color: 'black',
                            minWidth: 40,
                            border: '1px solid lightgrey',
                          }}
                        >
                          -
                        </Button>
                        <Typography sx={{ mx: 2 }}>{item.quantity}</Typography>
                        <Button
                          size="small"
                          onClick={() =>
                            updateQty('+', item.product._id, item.quantity)
                          }
                          sx={{
                            color: 'black !important',
                            minWidth: 40,
                            border: '1px solid lightgrey',
                          }}
                        >
                          +
                        </Button>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ color: colors.priceBefore }}>
                      {item.total}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Table sx={{ minWidth: 650, my: 5 }}>
            <TableHead>
              <TableRow sx={{ border: '1px solid lightgrey' }}>
                <TableCell sx={{ py: 5 }}>
                  <Typography
                    sx={{
                      fontSize: '2rem',
                      fontWeight: 'bold',
                      color: colors.orderSummary,
                    }}
                  >
                    {lng === 'en' ? 'Cart Totals' : 'إجمالي المبلغ'}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: '1px solid lightgrey' }}>
              <TableRow>
                {data?.data?.cashItems.totalPrice > 0 && (
                  <>
                    <TableCell sx={{ color: colors.orderSummaryBody }}>
                      {lng === 'en' ? 'Cash on delivery' : 'الدفع عند الإستلام'}
                    </TableCell>
                    <TableCell sx={{ color: colors.orderSummaryBody }}>
                      {data?.data?.cashItems.totalPrice}
                    </TableCell>
                  </>
                )}
              </TableRow>
              <TableRow>
                {data?.data?.onlineItems.totalPrice > 0 && (
                  <>
                    <TableCell sx={{ color: colors.orderSummaryBody }}>
                      {lng === 'en' ? 'Online payment' : 'الدفع اونلاين'}
                    </TableCell>
                    <TableCell sx={{ color: colors.orderSummaryBody }}>
                      {data?.data?.onlineItems.totalPrice}
                    </TableCell>
                  </>
                )}
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ color: colors.orderSummaryBody, fontWeight: 'bold' }}
                >
                  {lng === 'en' ? 'Total' : 'الإجمالي'}
                </TableCell>
                <TableCell
                  sx={{ color: colors.orderSummaryBody, fontWeight: 'bold' }}
                >
                  {data?.data?.totalPrice}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Button
            variant="contained"
            disabled={isError}
            sx={{
              width: '100%',
              py: 1.8,
              color: colors.button,
              bgcolor: `${colors.buttonBg} !important`,
            }}
            onClick={() => nav('/checkout')}
          >
            {lng === 'en' ? 'Checkout' : 'الدفع'}
          </Button>
        </>
      )}
    </Container>
  )
}
