import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MenuIcon from '@mui/icons-material/Menu'
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined'
import { Badge, Grid, Menu, MenuItem, Stack, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as React from 'react'
// import { ReactElement } from 'react'
import CallIcon from '@mui/icons-material/Call'
import CategoryIcon from '@mui/icons-material/Category'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import MapsUgcIcon from '@mui/icons-material/MapsUgc'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavDrawer } from './NavDrawer'
import { NavItems, NavTypes } from './NavTypes'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import Logo from './Logo'
import { NavData } from './navdata'
import ProfileButton from './publicComponents/ProfileButton'
import { NavLink } from 'react-router-dom'
import NavMenu from './NavMenu'
import { useGetAllCartsQuery } from '../src/APIs/cartApi'
import { useGetAllSavedProductsQuery } from '../src/APIs/SavedProductApi'
import NavCategoryMenu from './NavCategoryMenu'
export const Navbar = (props: NavTypes) => {
  const [, { language: lng, changeLanguage }] = useTranslation()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const theme = useTheme()
  const phoneScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const navigate = useNavigate()
  const navLinks = NavData()
  React.useEffect(() => {
    if (props.direction === 'vertical') {
      if (window.innerWidth > 1200) {
        document.body.style.width = 'calc(100% - 252px)'
      } else {
        document.body.style.width = '100%'
      }
      document.body.style.direction = lng === 'en' ? 'rtl' : 'ltr'
    } else {
      document.body.style.width = '100%'
    }
  }, [lng, props.direction, window.innerWidth])
  const muiIcons = [
    <HomeIcon
      key="home"
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
    <CategoryIcon
      key={'category'}
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
    <InfoIcon
      key={'info'}
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
    <MapsUgcIcon
      key={'message'}
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
    <CallIcon
      key={'call'}
      sx={{
        color: 'A3A3A3',
        ml: lng === 'en' ? '-4px' : '8px',
        mr: lng === 'en' ? '8px' : '-4px',
      }}
    />,
  ]
  const toggleLanguage = () => {
    if (lng === 'en') {
      changeLanguage('ar')
    } else {
      changeLanguage('en')
    }
  }
  const appBarZindex = theme.zIndex.drawer + 1
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [activeButton, setActiveButton] = React.useState<null | string>(null)
  const { data: dataCart, error: errorCart } = useGetAllCartsQuery(undefined)
  const { data: favPros, error: errorFav } =
    useGetAllSavedProductsQuery(undefined)
  const handleClick = (
    event: React.MouseEvent<any, MouseEvent>,
    buttonId: string
  ) => {
    setAnchorEl(event.currentTarget)
    setActiveButton(buttonId)
  }
  const { pathname } = useLocation()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Box>
      <Box
        sx={{
          display:
            pathname === '/sign-in' ||
            pathname === '/register' ||
            pathname === '/thankYou' ||
            pathname === '/forgetPassword'
              ? 'none'
              : 'flex',
          direction: lng === 'en' ? 'ltr' : 'rtl',
          flexDirection: props.direction === 'horizontal' ? 'row' : 'column',
          position: props.direction === 'horizontal' ? 'relative' : 'fixed',
          zIndex: props.direction === 'vertical' ? appBarZindex : 0,
          width: {
            lg: props.direction === 'vertical' ? 250 : '100%',
            xs: '100%',
          },
          height: {
            lg: props.direction === 'vertical' ? '100vh' : 'inherit',
            xs: 'inherit',
          },
          top: 0,
          left: lng === 'en' ? 0 : 'auto',
          right: lng === 'ar' ? 0 : 'auto',
        }}
      >
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{
            bgcolor: props.navBg ? props.colors.backgroundColor : 'white',
            position: 'relative',
            boxShadow: 'none',
            height: 'inherit',
          }}
        >
          <Toolbar sx={{ height: 'inherit' }}>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { lg: 'none' },
                color: props.colors.iconColor,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid
              container
              sx={{
                height: 'inherit',
                justifyContent: { xs: 'flex-end', lg: 'space-between' },
                alignItems: 'center',
                flexWrap: props.direction === 'vertical' ? 'wrap' : 'nowrap',
              }}
            >
              <Grid item xs={props.direction === 'vertical' && 12}>
                {!phoneScreen && (
                  <Box
                    sx={{
                      height: '80px',
                      width: '165px',
                      mx: 'auto',
                    }}
                  >
                    <Logo
                      imagePath={props.logoPath}
                      extraObjectFit={'contain'}
                    />
                  </Box>
                )}
              </Grid>
              {!phoneScreen && (
                <Grid
                  item
                  xs={props.direction === 'vertical' && 12}
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    mx: props.direction === 'vertical' ? 'auto' : 0,
                  }}
                >
                  <Stack
                    direction={
                      props.direction === 'horizontal' ? 'row' : 'column'
                    }
                    alignItems={
                      props.direction === 'horizontal' ? 'center' : 'flex-start'
                    }
                    justifyContent={'center'}
                    flexWrap={'wrap'}
                    columnGap={props.blockStyle ? 0 : 8}
                    rowGap={props.direction === 'vertical' ? 2 : 0}
                    gap={!props.blockStyle ? '20px' : 0}
                    py={1}
                  >
                    {navLinks.map((item: NavItems, index: number) =>
                      !item.nestedLinks ? (
                        <Button
                          component={NavLink}
                          key={index}
                          id={`button${index}`}
                          aria-controls={
                            activeButton === `button${index}`
                              ? `menu${index}`
                              : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={
                            activeButton === `button${index}`
                              ? 'true'
                              : undefined
                          }
                          to={item?.path}
                          sx={{
                            width: props.direction === 'vertical' ? 1 : 170,
                            mb: 1,

                            position: 'relative',
                            color: props.colors.linkTextColor,
                            bgcolor: `${props.colors.linkTextBgColor} !important`,
                            fontSize: {
                              xs: '1rem',
                              md: '1rem',
                              lg: '1.2rem',
                            },
                            textTransform: 'capitalize',
                            borderRadius: props.buttonStyle
                              ? props.buttonStyle === 'radius'
                                ? 3
                                : 6
                              : 0,
                            p: props.blockStyle
                              ? '.7rem 1rem'
                              : props.buttonStyle
                              ? '8px 10px'
                              : 0,
                            border:
                              props.blockStyle || props.buttonStyle
                                ? `1px solid ${props.colors.borderColor}`
                                : 'none',
                            '&.active': {
                              borderBottom: !props.buttonStyle
                                ? `2px solid ${props.colors.activeBorderColor}`
                                : `1px solid ${props.colors.borderColor}`,
                              color: !props.buttonStyle
                                ? !props.blockStyle
                                  ? props.colors.activeLinkColor
                                  : props.colors.linkTextColor
                                : props.colors.activeLinkColor,
                              bgcolor: `${
                                props.buttonStyle
                                  ? props.colors.activeLinkBgColor
                                  : props.colors.linkTextBgColor
                              } !important`,
                            },
                          }}
                          startIcon={props.icons && muiIcons[index]}
                          endIcon={
                            props.menu &&
                            item?.nestedLinks &&
                            item?.nestedLinks?.length > 0 && (
                              <KeyboardArrowDownIcon
                                sx={{
                                  mr: lng === 'en' ? '-4px' : '8px',
                                  ml: lng === 'en' ? '0px' : '-4px',
                                }}
                                onClick={(event) => {
                                  handleClick(event, `button${index}`)
                                }}
                              />
                            )
                          }
                        >
                          {lng === 'en' ? item.link_en : item.link_ar}
                        </Button>
                      ) : !props.menu ? (
                        <>
                          {item.nestedLinks.length > 1 && (
                            <Button
                              sx={{
                                width: props.direction === 'vertical' ? 1 : 170,
                                mb: 1,

                                border:
                                  props.blockStyle || props.buttonStyle
                                    ? `1px solid ${props.colors.borderColor}`
                                    : 'none',
                                borderBottom: !props.buttonStyle
                                  ? `2px solid ${
                                      pathname === `/departments`
                                        ? props.colors.activeBorderColor
                                        : null
                                    }`
                                  : `1px solid ${props.colors.borderColor}`,
                                color: props.blockStyle
                                  ? props.colors.linkTextColor
                                  : pathname === `/departments`
                                  ? props.colors.activeLinkColor
                                  : props.colors.linkTextColor,
                                bgcolor: `${
                                  pathname === '/departments'
                                    ? !props.blockStyle
                                      ? !props.buttonStyle
                                        ? 'transprent'
                                        : props.colors.activeLinkBgColor
                                      : props.colors.linkTextBgColor
                                    : props.colors.linkTextBgColor
                                } !important`,
                                cursor: 'pointer',
                                fontSize: {
                                  xs: '1rem',
                                  md: '1rem',
                                  lg: '1.2rem',
                                },
                                textTransform: 'capitalize',
                                borderRadius: props?.buttonStyle
                                  ? props.buttonStyle === 'radius'
                                    ? 3
                                    : 6
                                  : 0,
                                p: props?.blockStyle
                                  ? '.7rem 1rem'
                                  : props.buttonStyle
                                  ? '8px 10px'
                                  : 0,
                              }}
                              onClick={() => navigate(`/departments`)}
                            >
                              {lng === 'en' ? 'All Categories' : 'جميع الأقسام'}
                            </Button>
                          )}
                          {item.nestedLinks?.map((element: any) =>
                            !element.subs[0] ? (
                              <Button
                                key={element.id}
                                title={
                                  element[`title_${lng === 'en' ? 'en' : 'ar'}`]
                                    .length > 12
                                    ? element[
                                        `title_${lng === 'en' ? 'en' : 'ar'}`
                                      ]
                                    : null
                                }
                                sx={{
                                  width:
                                    props.direction === 'vertical' ? 1 : 170,
                                  mb: 1,

                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',

                                  border:
                                    props.blockStyle || props.buttonStyle
                                      ? `1px solid ${props.colors.borderColor}`
                                      : 'none',
                                  borderBottom: !props.buttonStyle
                                    ? `2px solid ${
                                        pathname ===
                                        `/departments/${element.id}`
                                          ? props.colors.activeBorderColor
                                          : null
                                      }`
                                    : `1px solid ${props.colors.borderColor}`,
                                  color: props.blockStyle
                                    ? props.colors.linkTextColor
                                    : pathname === `/departments/${element.id}`
                                    ? props.colors.activeLinkColor
                                    : props.colors.linkTextColor,
                                  bgcolor: `${
                                    pathname === `/departments/${element.id}`
                                      ? !props.blockStyle
                                        ? !props.buttonStyle
                                          ? 'transparent'
                                          : props.colors.activeLinkBgColor
                                        : props.colors.linkTextBgColor
                                      : props.colors.linkTextBgColor
                                  } !important`,
                                  cursor: 'pointer',
                                  fontSize: {
                                    xs: '1rem',
                                    md: '1rem',
                                    lg: '1.2rem',
                                  },
                                  textTransform: 'capitalize',
                                  borderRadius: props.buttonStyle
                                    ? props.buttonStyle === 'radius'
                                      ? 3
                                      : 6
                                    : 0,
                                  p: props.blockStyle
                                    ? '.7rem 1rem'
                                    : props.buttonStyle
                                    ? '8px 10px'
                                    : 0,
                                }}
                                onClick={() =>
                                  navigate(`/departments/${element.id}`)
                                }
                              >
                                {element[`title_${lng}`].length > 13
                                  ? element[`title_${lng}`].slice(0, 13) + '...'
                                  : element[`title_${lng}`]}
                              </Button>
                            ) : (
                              <NavCategoryMenu item={element} {...props} />
                            )
                          )}
                        </>
                      ) : (
                        <NavMenu {...props} item={item} />
                      )
                    )}
                  </Stack>
                </Grid>
              )}
              <Grid
                item
                sx={{
                  flexDirection: {
                    xs: props.direction === 'vertical' ? 'row-reverse' : 'row',
                  },
                }}
                lg={props.direction === 'vertical' && 12}
              >
                <Stack
                  direction={{
                    lg: props.direction === 'vertical' ? 'column' : 'row',
                    xs: 'row',
                  }}
                  alignItems={'center'}
                  gap={2}
                >
                  <Badge
                    badgeContent={
                      dataCart && !errorCart ? dataCart.data.totalQuantity : 0
                    }
                    sx={{
                      '.MuiBadge-badge': {
                        bgcolor: `${props.colors.badgeColors.bg} !important`,
                        color: `${props.colors.badgeColors.text} !important`,
                      },
                    }}
                  >
                    <AddShoppingCartOutlinedIcon
                      onClick={() => navigate('/cart')}
                      sx={{
                        color: pathname.includes('/cart')
                          ? props.colors.activeIconColor
                          : props.colors.iconColor,
                        cursor: 'pointer',
                        fontSize: {
                          xs: '1.5rem',
                          sm: '1.8rem',
                          md: '2.2rem',
                          lg: '2.3rem',
                        },
                      }}
                    />
                  </Badge>
                  <Badge
                    badgeContent={
                      favPros && !errorFav ? favPros.data.favourite.length : 0
                    }
                    sx={{
                      '.MuiBadge-badge': {
                        bgcolor: `${props.colors.badgeColors.bg} !important`,
                        color: `${props.colors.badgeColors.text} !important`,
                      },
                    }}
                  >
                    <FavoriteBorderOutlinedIcon
                      onClick={() => navigate('/savedProducts')}
                      sx={{
                        color: pathname.includes('/savedProducts')
                          ? props.colors.activeIconColor
                          : props.colors.iconColor,
                        cursor: 'pointer',
                        fontSize: {
                          xs: '1.5rem',
                          sm: '1.8rem',
                          md: '2.2rem',
                          lg: '2.3rem',
                        },
                      }}
                    />
                  </Badge>
                  <ProfileButton
                    menuBgColor={props.colors.menuBgColor || 'transparent'}
                    iconColor={props.colors.buttonColor || 'black'}
                    bgColor={props.colors.buttonBgColor || 'transparent'}
                    textColor={props.colors.menuItemColor || 'black'}
                    menuItemBgColor={
                      props.colors.menuItemBgColor || 'transparent'
                    }
                    activeColor={
                      props.colors.activeLinkBgColor || 'transparent'
                    }
                  />
                  <Button
                    onClick={toggleLanguage}
                    sx={{
                      minWidth: 0,
                      width: {
                        md: 50,
                        xs: 40,
                      },
                      height: {
                        md: 40,
                        xs: 30,
                      },
                      color: props.colors.buttonColor,
                      bgcolor: `${props.colors.buttonBgColor} !important`,
                      fontWeight: 'bold',
                    }}
                  >
                    {lng === 'en' ? 'Ar' : 'En'}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <NavDrawer
          {...props}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          muiIcons={muiIcons}
        />
      </Box>
    </Box>
  )
}
