import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { NavTypes, NavItems } from './NavTypes'
import { useTranslation } from 'react-i18next'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useLocation, useNavigate } from 'react-router-dom'
import NavNestedCategoryMenu from './NavNestedCategoryMenu'
interface NavMenuProps extends NavTypes {
  item: NavItems
  handleDrawerToggle?: () => void
}
export default function NavMenu(props: NavMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const [_, { language: lng }] = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box
      sx={{
        width: {
          lg: props.direction === 'vertical' ? 1 : 170,
          xs: props.blockStyle ? 1 : 0.9,
        },
        mx: {
          lg: 0,
          xs: 'auto',
        },
        mb: {
          lg: '7px',
          xs: props.blockStyle ? 0 : '10px',
        },
        pr: {
          lg: 0,
          xs: lng === 'ar' ? '2px' : undefined,
        },
        pl: {
          lg: 0,
          xs: lng === 'en' ? '2px' : undefined,
        },
        borderLeft: {
          lg: 0,
          xs:
            lng === 'en'
              ? props.buttonStyle && pathname.includes('/departments')
                ? `4px solid ${props.colors.activeDrawerColor}`
                : `1px solid transparent`
              : `1px solid transparent`,
        },
        borderRight: {
          lg: 0,
          xs:
            lng === 'ar'
              ? props.buttonStyle && pathname.includes('/departments')
                ? `4px solid ${props.colors.activeDrawerColor}`
                : `1px solid transparent`
              : `1px solid transparent`,
        },
      }}
    >
      <Button
        sx={{
          mx: {
            lg: 0,
            xs: 'auto',
          },

          width: 1,
          display: 'flex',
          justifyContent: {
            lg: 'center',
            xs: 'space-between',
          },
          border:
            props.blockStyle || props.buttonStyle
              ? `1px solid ${props.colors.borderColor}`
              : 'transparent',
          borderBottom: {
            lg: !props.buttonStyle
              ? pathname.includes('/departments')
                ? `2px solid ${props.colors.activeBorderColor}`
                : `1px solid ${
                    props.blockStyle ? props.colors.borderColor : 'transparent'
                  }`
              : `1px solid ${props.colors.borderColor}`,
            xs: `1px solid ${
              !props.blockStyle && !props.buttonStyle
                ? 'transparent'
                : props.colors.borderColor
            }`,
          },
          color: {
            lg: props.buttonStyle
              ? pathname.includes('/departments')
                ? props.colors.activeLinkColor
                : props.colors.linkTextColor
              : pathname.includes('/departments') 
              ? props.colors.activeLinkColor
              : props.colors.linkTextColor,
            xs: pathname.includes('/departments')
              ? props.colors.activeDrawerColor
              : props.colors.drawerColor,
          },
          bgcolor: {
            lg: `${
              !props.buttonStyle
                ? props.colors.linkTextBgColor
                : pathname.includes('/departments')
                ? props.colors.activeLinkBgColor
                : props.colors.linkTextBgColor
            } !important`,
            xs: `${props.colors.linkTextBgColor} !important`,
          },
          cursor: 'pointer',
          fontSize: {
            xs: '1rem',
            md: '1rem',
            lg: '1.2rem',
          },
          textTransform: 'capitalize',
          borderRadius: props.buttonStyle
            ? props.buttonStyle === 'radius'
              ? 3
              : 6
            : 0,
          p: props.blockStyle
            ? '.7rem 1rem'
            : props.buttonStyle
            ? '8px 10px'
            : 0,
          gap: '7px',
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {props.item.icon}
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            width: {
              lg: 'auto',
              xs: !props.blockStyle && !props.buttonStyle ? 0.85 : 0.55,
            },
          }}
        >
          <span>{props.item[`link_${lng === 'en' ? 'en' : 'ar'}`]}</span>
          <ArrowDropDownIcon
            sx={{
              transition: 'all 0.4s',
              color: {
                lg: props.buttonStyle
                  ? pathname.includes('/departments')
                    ? props.colors.activeLinkColor
                    : props.colors.linkTextColor
                  : pathname.includes('/departments') && !props.blockStyle
                  ? props.colors.activeLinkColor
                  : props.colors.linkTextColor,
                xs: pathname.includes('/departments')
                  ? props.colors.activeDrawerColor
                  : props.colors.drawerColor,
              },
              transform: open ? 'rotate(180deg)' : 'rotatex(0)',
            }}
          />
        </Stack>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '.MuiList-root': {
            width: {
              lg: '100% !important',
              xs: '225px !important',
            },
            '.MuiButtonBase-root': {
              justifyContent: 'center',
            },
          },
          '.MuiPaper-root': {
            bgcolor: props.colors.menuBgColor,
          },
        }}
      >
        {props.item.nestedLinks.map((item: any) =>
          item.subs?.length > 0 ? (
            <NavNestedCategoryMenu
              {...props}
              item={item}
              handleClose={handleClose}
            />
          ) : (
            <MenuItem
              key={item.id}
              sx={{
                mb: '5px',

                bgcolor: `${
                  pathname === `/departments/${item.id}`
                    ? props.colors.activeMenuItemBgColor
                    : props.colors.menuItemBgColor
                } !important`,
                color:
                  pathname === `/departments/${item.id}`
                    ? props.colors.activeMenuItemColor
                    : props.colors.menuItemColor,
              }}
              onClick={() => {
                handleClose()
                navigate(`/departments/${item.id}`)
                if (props.handleDrawerToggle) {
                  props.handleDrawerToggle()
                }
              }}
            >
              {item[`title_${lng}`]}
            </MenuItem>
          )
        )}
        {props.item.nestedLinks.length > 1 && (
          <MenuItem
            sx={{
              bgcolor: `${
                pathname === `/departments`
                  ? props.colors.activeMenuItemBgColor
                  : props.colors.menuItemBgColor
              } !important`,
              color:
                pathname === `/departments`
                  ? props.colors.activeMenuItemColor
                  : props.colors.menuItemColor,
            }}
            onClick={() => {
              handleClose()
              navigate(props.item.path)
              if (props.handleDrawerToggle) {
                props.handleDrawerToggle()
              }
            }}
          >
            {lng === 'en' ? 'All categories' : 'جميع الأقسام'}
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
