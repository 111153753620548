import { CardMedia } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface LogoType {
  imagePath: string
  extraObjectFit: string | undefined
}
function Logo({ imagePath, extraObjectFit }: LogoType) {
  const navigate = useNavigate()
  return (
    imagePath && (
      <CardMedia
        component="img"
        onClick={() => navigate('/')}
        sx={{
          height: '100%',
          width: '100%',
          objectFit: extraObjectFit ? extraObjectFit : 'cover',
          cursor: 'pointer',
        }}
        src={imagePath}
      />
    )
  )
}

export default Logo
